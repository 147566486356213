// Responsive fonts. "Include media" settings.

@function calcFluidFontSize($f-min, $f-max, $v-min, $v-max, $units: px) {
  $k: calc(($f-max - $f-min) / ($v-max - $v-min));
  $k: calc(($k * 100) / 100);
  $b: calc($f-min - $k * $v-min);
  $b: calc(($b * 100) / 100);
  $b: $b + $units;
  @return calc(#{$k} * 100vw + #{$b});
}

// Responsive breakpoints. "Include media" settings.
$s-1920: 1920px;
$s-1440: 1440px;
$s-1360: 1360px;
$s-1200: 1200px;
$s-1024: 1024px;
$s-768: 768px;
$s-640: 640px;

@mixin s-1920 {
  @media (max-width: #{$s-1920}) {
    @content;
  }
}

@mixin s-1440 {
  @media (max-width: #{$s-1440}) {
    @content;
  }
}

@mixin s-1360 {
  @media (max-width: #{$s-1360}) {
    @content;
  }
}

@mixin s-1200 {
  @media (max-width: #{$s-1200}) {
    @content;
  }
}

@mixin s-1024 {
  @media (max-width: #{$s-1024}) {
    @content;
  }
}

@mixin s-768 {
  @media screen and (max-width: #{$s-768}) {
    @content;
  }
}

@mixin s-640 {
  @media screen and (max-width: #{$s-640}) {
    @content;
  }
}

@function pxVh($f-px, $vh) {
  $k: calc($f-px/$vh * 100);
  $k: calc(round($k * 100) / 100);
  $b: vh;
  @return #{$k}#{$b};
}

@function pxVw($f-px, $vw) {
  $k: calc($f-px/$vw * 100);
  $k: calc(round($k * 100) / 100);
  $b: vw;
  @return #{$k}#{$b};
}
$assetsPath: 'assets';
