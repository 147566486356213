.c-reviews{
    display: none;
    padding-top: 80px;
    padding-bottom: 80px;
    overflow: hidden;
    @include s-1200{
        padding-top: 64px;
        padding-bottom: 64px;
    }
    @include s-768{
        padding-top: 48px;
        padding-bottom: 48px;
    }
    &-wrap{

    }
    &-top{
        justify-content: space-between;
        display: flex;
        align-items: center;
        @include s-768{
            flex-wrap: wrap;
            text-align: center;
        }
        .item{
            &-left{
                flex: 1;
            }
            &-label{
                text-transform: uppercase;
            }
            &-stars{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                @include s-768{
                    display: block;
                    text-align: center;
                }
                &-icon{
                    width: 160px;
                    @include s-768{
                        padding-top: 8px;
                        margin: 0 auto;
                    }
                    img{
                        width: 100%;
                    }
                }
                &-desc{
                    padding-left: 16px;
                    @include s-768{
                        padding-top: 8px;
                        width: 100%;
                    }
                }

            }
            &-btn{
                flex: 0 0 225px;
                max-width: 225px;
                @include s-768{
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding-top: 24px;
                }
                .btn-border{
                    max-width: 100%;
                }
            }
        }
    }

    &-slider{
        padding-top: 70px;
        @include s-1200{
            padding-top: 40px;
        }
        &-wrap{
            margin: 0 auto;
            position: relative;
            padding-left: 32px;
            padding-right: 32px;
            max-width: 1264px;
            @include s-1200{
                max-width: 100%;
                padding: 0;
            }
        }
        .slick-list {
            // @include s-1200{
            //     overflow: visible!important;
            // }

        }
        &-ul{
            display: flex;
			flex-wrap: nowrap;
			margin: 0 -16px;
            @include s-1200{
                margin: 0;
            }
        }
        &-li{
            padding-left: 16px;
            padding-right: 16px;
            min-height: 340px;
            @include s-1200{
                padding-left: 8px;
                padding-right: 8px;
            }
            .item{
                padding: 30px 40px;
                border-radius: 22px;
                border: 2px solid var(--color-grey-1);
                background: var(--color-white);
                height: 342px;
                display: block;
                @include s-1200{
                    max-width: 100%;
                    width: 100%;
                }
                @include s-640{
                    padding: 30px;
                }
                // @include s-768{
                //     margin: 0 auto;
                //     max-width: 380px;
                // }
                &-wrap{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                }
                &-label{
                    &-title{
                        color: var(--color-blue-1);
                    }
                }
                &-desc{
                    padding-top: 8px;

                }
                &-stars{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    &-icon{
                        width: 124px;
                        img{
                            width: 100%;
                        }
                    }
                    &-desc{
                        padding-left: 12px;
                    }

                }
                &-name{

                }
                &-position{

                }
            }
        }
        &-col{
            min-height: 340px;
            .item{
                padding: 30px 40px;
                border-radius: 22px;
                border: 2px solid var(--color-grey-1);
                background: var(--color-white);
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @include s-640{
                    padding: 30px;
                }
                &-wrap{

                }
                &-label{
                    &-title{
                        color: var(--color-blue-1);
                    }
                }
                &-desc{
                    padding-top: 8px;

                }
                &-stars{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    &-icon{
                        width: 124px;
                        img{
                            width: 100%;
                        }
                    }
                    &-desc{
                        padding-left: 12px;
                    }

                }
                &-name{

                }
                &-position{

                }
            }
        }
        &-nav{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 24px;
        }


    }



}
