.c-faq{
  &.component{
    padding-top: 80px;
    @include s-1024 {
      padding-top: 64px;
    }
    @include s-640 {
      padding-top: 48px;
     }
  }
  .title-block{
    text-align: center;
    padding-bottom: 32px;
    .item{
      &-title{
        &--title{

        }
      }
      &-desc{
        padding-top: 8px;

      }
    }
  }



  &-tabs{
    @include s-640{
      padding-left: 12px;
      overflow: auto;
      padding-bottom: 8px;
    }
    &-wrap{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-grey);
        max-width: 575px;
        margin: 0 auto;
        @include s-640{
          margin: 0;
          max-width: 420px;
          width: 420px;
        }
    }
    &-tab{
        cursor: pointer;
        color: var(--color-grey);
        font-size: 14px;
        text-transform: uppercase;
        padding: 10px;
        line-height: 1;
        font-family: var(--font-semi-mon);
        position: relative;
        @include s-1024{
            font-size: 12px;
        }
        @include s-640{
            font-size: 10px;
            padding: 8px;
        }
        &.active{
            color:var(--color-pink);
            &:after{
                content: '';
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 100%;
                height: 4px;
                border-bottom: 4px solid var(--color-pink);
            }
        }
    }
  }
  .c-tab {
    padding-top: 0;
    &-item {
      border-bottom: 1px solid var(--color-grey-1);
      padding: 0;
      transition: padding 0.4s ease-in-out;
      &.hidden{
        display: none;
      }
      &.active {
        padding: 0 0 16px 0;

        & .c-tab-item-title::after {
          // transform:translateY(-50%) rotate(180deg);
          transform:rotate(180deg);
        }
      }
      &-wrap {
        position: relative;

      }
      &-title {
        cursor: pointer;
        font-family: var(--font-med);
        font-size: 20px;
        line-height: 150%;
        padding: 16px 25px 16px 0;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          // top: 50%;
          top: 20px;
          right: 0;
          width: 25px;
          height: 25px;
          // transform: translateY(-50%);
          transition: all ease-in-out 0.4s;
          background: url('../icons/arrow-bottom.svg') no-repeat center center  ;
          background-size: contain;
          z-index: 10;
        }


      }
      &-block {
        height: 0;
        overflow: hidden;
        font-size: 18px;
        line-height: 24px;
        padding: 0 142px 0 0;
        max-width: 100%;
        @include s-1024{
          padding: 0 90px 0 0;
        }
        @include s-640{
          padding: 0 25px 0 0;
        }
      }
      &-desc {
        font-size: 16px;
        padding-left: 20px;
        &--desc {

        }

      }

    }
  }
  &-block{
    .c-tab {
      padding-top: 0;
      &-item {
        border-bottom: 1px solid var(--color-grey-1);
        padding: 0;
        transition: padding 0.4s ease-in-out;
        &.hidden{
          display: none;
        }
        &.active {
          padding: 0 0 16px 0;

          & .c-tab-item-title::after {
            // transform:translateY(-50%) rotate(180deg);
            transform:rotate(180deg);
          }
        }
        &-wrap {
          position: relative;

        }
        &-title {
          cursor: pointer;
          font-family: var(--font-med);
          font-size: 20px;
          line-height: 150%;
          padding: 16px 25px 16px 0;
          position: relative;
          color: var(--color-black);
          &:after {
            content: '';
            position: absolute;
            // top: 50%;
            top: 20px;
            right: 0;
            width: 25px;
            height: 25px;
            // transform: translateY(-50%);
            transition: all ease-in-out 0.4s;
            background: url('../icons/arrow-bottom.svg') no-repeat center center  ;
            background-size: contain;
            z-index: 10;
          }


        }
        &-block {
          height: 0;
          overflow: hidden;
          padding: 0 142px 0 0;
          max-width: 100%;
          @include s-1024{
            padding: 0 90px 0 0;
          }
          @include s-640{
            padding: 0 25px 0 0;
          }
        }
        &-desc {
          &--desc {
            color: var(--color-grey);
            font-size: 18px;
          }

        }

      }
    }
  }
}
