.p-case{
	&-content{
		background-color: var(--color-grey-2);
	}
	.c-index {
		height: auto;
		min-height: 640px;
		position: relative;
        background-color: var(--color-blue);
		@include s-1200 {
			min-height: 400px;
		}
		@include s-1200 {
			min-height: 460px;
		}
		&-content {
			width: 100%;
			position: relative;
			z-index: 2;
			&-wrap {
				width: 100%;
				margin: 0 auto;
				display: flex;
				justify-content: flex-start;
				padding-top: 116px;
				padding-bottom: 130px;
				@include s-1200 {
					justify-content: center;
					padding-top: 56px;
					padding-bottom: 100px;
				}
				@include s-768 {
					padding-top: 40px;
					text-align: center;
				}
			}
			&-col{
				width: 50%;
				@include s-1200 {
					width:100%;
				}
			}
			&-block{
				text-align: left;
				max-width: 742px;
				width: 100%;
				@include s-1200 {
					max-width: 100%;

				}

			}
			.item {
                &-label{
                    color: var(--color-white);
                }
				&-title {
					width: 100%;
					&--title {
						color: var(--color-white);
					}
				}
				&-desc {
					padding-top: 16px;
                    color: var(--color-white);

				}

			}
		}
	}
	.s-article{
		padding-top: 80px;
		@include s-1200{
			padding-top: 48px;
		}
		&-content{

		}
		&-row{
			justify-content: space-between;
			flex-wrap: wrap;
			&-top{
				display: grid;
				grid-template-columns: minmax(0, 65%) 458px;
				height: 100%;
				max-width: 1200px;
				margin: 0 auto;
				@include s-1200{
					display: block;
				}
			}
			&-bottom{

			}

		}
		&-col{
			flex: 0 0 65%;
			max-width: 774px;
			&-desc{
				@include s-1200{
					max-width: 100%;
					flex: 0 0 100%;
				}
			}
			&-first{
				grid-column: 1;
				grid-row: 1;
				padding: 0;
				max-width: 742px;
				padding-top: 0;
				@include s-1200{
					display: block;
				}
				.item{
					max-width: 742px;
					width: 100%;
					&:first-of-type{
						padding-top: 0;
					}
				}
			}
			&-third{
				max-width: 742px;
				grid-column: 1;
				grid-row: 2;
				padding: 0;
				@include s-1200{

				}
				.item{
					max-width: 742px;
					width: 100%;
				}
			}
			&-list{
				grid-column: 2;
				grid-row: 1 / 3;
				padding: 0 0 0 80px;
				@include s-1200{
					 padding-top: 48px;
					 padding-left: 0;
				}
				@include s-768{
					padding-top: 32px;
			    }
				.c-company{
					&-list{
						padding-top: 0;
					}
					&-row{
						flex-wrap: wrap;
						margin: 0;
						@include s-1200{
							margin: 0 -8px;
						}
						@include s-768{
							margin: 0;
						}
					}
					&-col{
						flex:  0 0 100%;
						max-width: 100%;
						padding: 0 0 32px;
						@include s-1200{
							flex:  0 0 33.33%;
							max-width: 33.33%;
							padding: 0 8px 0;
						}
						@include s-768{
							flex:  0 0 100%;
							max-width: 100%;
							padding: 0 0 16px;
						}
						&:last-of-type{
							padding-bottom: 0;
						}
						.item{
							&:first-of-type{
								padding-top: 32px;
							}
						}
					}
				}
			}
		}
		.item{
			max-width: 742px;
			width: 100%;
			padding-top: 32px;

			&-desc{
				padding-top: 16px;
				ul{
					list-style-position: outside;
					list-style-type: disc;
					padding-left: 32px;
				}
				li{

				}
			}
			&-list{
				margin: 0 -8px;
				display: flex;
				padding-top: 48px;
				flex-wrap: wrap;
				@include s-768{
					margin: 0;
					padding-top: 32px;
				}
				&-col{
					padding-left: 8px;
					padding-right: 8px;
					padding-bottom: 16px;
					flex: 0 0 33.33%;
					max-width: 33.33%;
					@include s-768{
						flex:  0 0 100%;
						max-width: 100%;
						padding: 0 0 16px;
					}
				}
				&-block{
					padding: 24px;
					border-radius: 14px;
					border: 2px solid var(--color-grey-1);
					height: 100%;
					.el-text-help{
						color: var(--color-blue);

					}
					.el-text-light{

					}
				}
			}
			&-img{
				img{
					width: 100%;
				}
			}
			&-bg{
				&-desc{
					.el-text{
						font-family: var(--font-med);
						color: var(--color-white);
					}
				}
				&-wrap{
					background-color: var(--color-blue);
					padding: 40px;
					border-radius: 22px;
				}
				&-bottom{
					padding-top: 24px;
					display: flex;
					justify-content: flex-start;
				}
				&-logo{
					flex: 0 0 67px;
					max-width: 67px;
				}
				&-right{
					flex: 1;
					padding-left: 16px;
				}
				&-name{
					color: var(--color-white);
				}
				&-position{
					color: var(--color-white);
				}

			}
			&-video{
				padding-top: 32px;
				&-wrap{
					// width: 100%;
					// height: auto;
					// position: relative;
					// #vimeo-container {
					// 	position: relative;
					// 	padding-bottom: 56.25%;
					// 	height: 0;
					// 	overflow: hidden;
					// 	max-width: 100%;
					// 	background-color: var(--color-grey-5);
					// }
					// #vimeo-container iframe {
					// 	position: absolute;
					// 	top: 0;
					// 	left: 0;
					// 	width: 100%;
					// 	height: 100%;
					// 	border: 0;
					// }
					.play-button{
						width: 95px;
						height: 95px;
					}
				}
			}
		}
	}
	.c-company{
		// padding-top: 80px;
		padding-bottom: 80px;
		@include s-1200{
			// padding-top: 48px;
			padding-bottom: 48px;
		}
		&-top{
			text-align: center;
		}
	}
}