.c-download{
    display: none;
    padding-top: 80px;
    padding-bottom: 80px;
    @include s-1200{
        padding-top: 64px;
        padding-bottom: 64px;
    }
    @include s-768{
        padding-top: 48px;
        padding-bottom: 48px;
    }
    &-wrap{
        background-color: var(--color-grey-1);
        border-radius: 22px;
        padding: 80px;
        @include s-768{
            padding: 32px;
        }
    }
    &-list{

    }
    &-row{
        justify-content: space-between;
        @include s-1200{
            flex-wrap: wrap;
        }
    }
    &-col{
        &-desc{
            max-width: 100%;
            flex: 1;
            padding-right: 92px;
            @include s-1200{
                max-width: 100%;
                flex: 0 0 100%;
                padding-top: 48px;
            }
            @include s-768{
                padding-right: 0;

            }
        }
        &-img{
            max-width: 380px;
            flex: 0 0 380px;
            @include s-1200{
                max-width: 100%;
                flex: 0 0 100%;
                order: -1;
            }
            @include s-768{

            }
            .item{
                &-img{
                    @include s-1200{
                        max-width: 374px;
                        margin: 0 auto;
                    }
                }
            }
            img{
                width: 100%;
            }
        }
    }
    .item{
        &-label{
            color: var(--color-grey);
        }
        &-title{
            padding-top: 8px;
            &--title{

            }
        }
        &-list{
            padding-top: 16px;
            padding-left: 24px;
            ul{
                list-style: disc;
                list-style-position: outside;
                color: var(--color-grey);
            }
        }
        &-form{
            padding-top: 32px;
            .form-item{
                &-email{
                    position: relative;
                    &-wrap{
                        position: relative;
                    }
                    input{
                        padding-left: 50px;
                        padding-right: 221px;
                        @include s-768{
                            padding-right: 16px;
                        }
                    }
                }
                &-actions{
                    position: absolute;
                    right: 0;
                    top: 0;
                    max-width: 221px;
                    padding: 0;
                    @include s-768{
                       position: relative;
                       max-width: 100%;
                    }
                }
            }

            .btn{
                max-width: 221px;
                @include s-768{
                    max-width: 100%;
                }

            }
        }
    }

}
