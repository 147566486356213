.p-industry {
	&-content{
		background-color: var(--color-grey-2);
	}
	.c-index {
		min-height: 500px;
		@include s-1200 {
			min-height: 595px;
		}
		@include s-768 {
			min-height: auto;
		}
		&-content {
			&-wrap{
				padding-top: 100px;
				padding-bottom: 100px;
				@include s-1200 {
					padding-top: 80px;
					padding-bottom: 80px;
				}
				@include s-768 {
					padding-top: 48px;
					padding-bottom: 48px;
				}
			}
			&-block{
				text-align: center;
				max-width: 890px;
				width: 100%;
				margin: 0 auto;
				@include s-1200 {
					max-width: 100%;
				}
			}

		}

	}
	.c-successes{
		@include s-1200{
			padding-top: 40px;
		}

	}
	.c-application{
		// @include s-768{
		// 	padding-top: 0;
		// }

	}
	.s-article{
		padding-top: 80px;
		@include s-1200{
			padding-top: 48px;
		}
		&-content{

		}
		&-row{
			padding-top: 100px;
			justify-content: space-between;
			@include s-1200{
				padding-top: 64px;
			}
			@include s-768{
				padding-top: 48px;
			}
			&:first-of-type{
				padding-top: 0;
			}
		}
		&-col{
			max-width:582px;
			flex: 0 0 50%;
			@include s-1200{
				max-width: 361;
				flex: 0 0 50%;
			}
			&-img{
				@include s-768{
					display: none;
				}
			}
			&-desc{
				@include s-768{
					max-width: 100%;
					flex: 0 0 100%;
				}
			}
		}
		.item{
			max-width: 550px;
			width: 100%;
			&-img{
				img{
					width: 100%;
				}
			}
			&-faq{
				padding-top: 24px;
			}
		}
	}

}
