.c-index {
    height: auto;
    min-height: 640px;
    position: relative;
    &-wrap{
        position: relative;
        max-width: 1440px;
        margin: 0 auto;
        width: 100%;
    }
    &-content {

        width: 100%;
        position: relative;
        z-index: 2;
        &-wrap {
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            padding-top: 100px;
            @include s-1200 {
                justify-content: center;
                padding-top: 56px;
            }
            @include s-768 {
                padding-top: 48px;
                text-align: center;
            }
        }
        &-col{
            width:100%;
        }
        &-block{
            text-align: center;
            max-width: 803px;
            width: 100%;
            margin: 0 auto;
            @include s-1200 {
                max-width: 100%;
            }


        }
        .item {
            &-label{
                font-size: 18px;
                font-family: var(--font-med);
                line-height: 130%;
                text-transform: uppercase;
            }
            &-title {
                width: 100%;
                padding-top: 8px;
                &--title {
                    // color: var(--color-pink);
                }
            }
            &-desc {
                padding-top: 16px;
            }
            &-btns {
                padding-top: 48px;
                display: flex;
                justify-content: center;
                @include s-768 {
                    flex-wrap: wrap;
                }
            }
            &-btn{
                flex: 0 0 50%;
                max-width: 225px;
                &:first-of-type{
                    margin-right: 24px;
                    @include s-768 {
                        margin-right: 0;
                        margin-bottom: 16px;
                    }
                }
                @include s-768 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                .btn{
                    @include s-768 {
                        max-width: 100%;
                    }
                }
            }

        }
    }

    &-bg {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        .item {
            &-img {
                &-picture{
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: relative;
                }
                &--img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

}
