h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

p {
  font-family: var(--font-reg);
  line-height: 150%;
  font-size: 18px;
  margin-bottom: 32px;
  &:last-of-type {
    margin-bottom: 0;
  }
 }

h1,
.el-title--h1 {
  color: var(--color-blue);
  font-family: var(--font-bold);
  line-height: 130%;
  text-transform: capitalize;
  font-size: 56px;
  @include s-640 {
    font-size: 44px;
  }
}

h2,
.el-title--h2 {
  color: var(--color-blue);
  font-family: var(--font-bold);
  line-height: 130%;
  text-transform: capitalize;
  font-size: 44px;
  @include s-640 {
    font-size: 34px;
  }
}

h3,
.el-title--h3 {
  color: var(--color-blue);
  font-family: var(--font-semi);
  line-height: 130%;
  font-size: 30px;
}

h4,
.el-title--h4 {
  font-family: var(--font-med);
  line-height: 150%;
  font-size: 20px;
}

.el-text-upper {
  color: var(--color-black);
  font-family: var(--font-med);
  text-transform: uppercase;
  line-height: 130%;
  font-size: 18px;
}
.el-text-help {
  color: var(--color-grey);
  font-family: var(--font-med);
  line-height: 150%;
  font-size: 20px;
}

.el-text {
  color: var(--color-black);
  font-family: var(--font-reg);
  line-height: 150%;
  font-size: 18px;
}

.el-text-helping,.el-text-light  {
  color: var(--color-grey);
  font-family: var(--font-light);
  line-height: 140%;
  font-size: 14px;
  letter-spacing: 0.16px;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}



.fancybox{
    &-bg {
        background-color: rgba(0, 0, 0, 0.8) !important;
    }

    &__arrow--left,
    &__arrow--right {
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        width: 50px;
        height: 50px;
    }

    &__arrow--left::before,
    &__arrow--right::before {
        color: #fff;
        font-size: 20px;
    }

    &__button--close {
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        top: 10px;
        right: 10px;
    }

    &__button--close::before {
        color: #fff;
        font-size: 20px;
    }
}

.item-img-picture{
  display: block;
}
.item-img--img{
  display: block;
}


.video{
	&-wrap{
		width: 100%;
		height: auto;
		position: relative;
		padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
		.video-container iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 0;
		}
		.video-preview {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			z-index: 10;
			transition: opacity 0.2s linear;
			&.hidden{
				visibility: hidden;
				opacity: 0;
			}
		  }
		  .item{
			&-img{
				position: relative;
				width: 100%;
				height: 100%;
			}
		  }
		  .preview-image {
			width: 100%;
			height: 100%;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		  }

		  .play-button {
			position: absolute;
			width: 154px;
			height: 154px;
			background: url('../icons/play.svg') no-repeat center center  ;
			background-size: contain;
			border: none;
			cursor: pointer;
			@include s-1200{
				width: 98px;
				height: 98px;
			}
			@include s-768{
				width: 45px;
				height: 45px;
			}
		  }

		  .vimeo-player {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		  }
	}
}
.c-editor{
  .item{
    padding-bottom: 48px;
  }
  h1,h2,h3,h4,h5{
    padding-bottom: 16px;
    line-height: 120%;
    color: var(--color-blue);
    font-family: var(--font-semi);
  }
  h1{
    font-size: 44px;
    font-family: var(--font-bold);
  }
  h2{
    font-size: 44px;
    font-family: var(--font-bold);
  }
  h3{
    font-size: 30px;
  }
  h4{
    font-size: 30px;
  }
  h5{
    font-size: 24px;
  }
  h6{
    font-size: 18px;
  }

  p{
    margin-bottom: 32px;
    font-size: 18px;
    font-family: var(--font-reg);
    line-height: 150%;
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  ul{
    // padding-top: 30px;
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 40px;
    font-size: 18px;
    margin-bottom: 32px;
  }
  ol{
    // padding-top: 30px;
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: 20px;
    font-size: 18px;
    margin-bottom: 32px;
  }
  li{
    // padding-top: 25px;
  }
  a{
    font-size: 18px;
    color: var(--color-black);
    text-decoration: underline;
    font-weight: var(--font-semi);
    &:hover{
      text-decoration: none;
    }
  }
  strong{
    font-weight: var(--font-bold);
  }
  em{
    font-style: italic;
  }
}
