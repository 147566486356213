.c-compliance{
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: var(--color-white);
    @include s-1200{
        padding-top: 64px;
        padding-bottom: 64px;
    }
    @include s-768{
        padding-top: 48px;
        padding-bottom: 48px;
    }
    &-wrap{

    }
    &-row{
        margin: 0 -24px;
        @include s-1200{
            margin: 0 -8px;
            flex-wrap: wrap;
        }
        @include s-768{
            justify-content: center;
            margin: 0 auto;
            max-width: 440px;
        }
    }
    &-col{
        padding-left: 24px;
        padding-right: 24px;
        @include s-1200{
            max-width: 25%;
            flex: 0 0 25%;
            padding-left: 8px;
            padding-right: 8px;
            padding-bottom: 24px;
        }
        @include s-768{
            // max-width: 50%;
            flex: 0 0 50%;
            max-width: 148px;
        }
    }
    .title-block{
        .item{
            &-title{
                text-align: center;
            }
            &-desc{
                padding-top: 16px;
                text-align: center;
            }
        }
    }
    &-content{
        padding-top: 24px;
    }

    .item{
        &-icon{
            text-align: center;
            width: 80px;
            height: 80px;
            margin:  0 auto;
            display: block;
        }
        &-desc{
            padding-top: 12px;
            text-align: center;
            display: block;
        }
        &-more{
            text-align: center;
        }
    }
}
