/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-active {
  border: none;
}

.slick-dotted {
  &.slick-slider {
    margin-bottom: 0;
  }
}

.slick-dots {
  // position: absolute;
  // //bottom: 10px;
  // bottom: 0;
  // width: 100%;
  // left: 0;
  display: flex;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
  li {
    width: 8px;
    height: 8px;
    margin: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      cursor: pointer;
      text-indent: -9999px;
      width: 8px;
      height: 8px;
      padding: 0;
      border-radius: 15px;
      background-color: transparent;
      border: 1px solid var(--color-grey-4);
      //transform: rotate(-45deg);
      transition: all 0.3s cubic-bezier(.23,1,.32,1);

      &:before {
        display: none;
      }
      &:hover, &:active {
        background-color: var(--color-grey-4);
        border: 2px solid var(--color-grey-4);
        width: 8px;
        height: 8px;
      }
    }

    &.slick-active button {
      background-color: var(--color-grey-4);
      border: 2px solid var(--color-grey-4);
      width: 8px;
      height: 8px;
    }
  }
}


.slick-track {

}
.slick-arrow-block{
  display: flex;
  justify-content: center;
}
// .slick-arrow {
//   position: absolute;
//   top: 50%;
//   left: auto;
//   right: -40px;
//   z-index: 600;
//   width: 10px;
//   height: 15px;
//   border: 0 none;
//   cursor: pointer;
//   background-color: transparent;
//   padding: 0;
//   transform: translate(0,-50%);
//   &.slick-prev {
//     right: auto;
//     left: -40px;
//     width: 10px;
//     height: 15px;
//   }
// }

// .slick-prev:before, .slick-next:before {
//   display: block;
//   opacity: 1;
// }

.slick-arrow {
  &.arrow-btn{
    width: 50px;
    height: 50px;
    border: 2px solid var(--color-grey-1);
    background-color: var(--color-white);
    position: relative;
    border-radius: 12px;
    cursor: pointer;
    position: relative;

    // @include s-1024 {
    //   width: 40px;
    //   height: 40px;
    // }
    // @include s-640 {
    //   width: 28px;
    //   height: 28px;
    // }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      right: 0;
      z-index: 1;
      background-image: url('../icons/arrowsimple-left.svg');
      background-attachment: scroll;
      background-repeat: no-repeat;
      background-position: center;
      // background-size: 18px 18px;
      display: block;
      // @include s-640 {
      //    background-size: 14px 14px;
      // }
    }
    &:hover{
      &:before{
        background-image: url('../icons/arrowsimple-left-active.svg');
      }
    }
    &.arrow-btn-next{
      &:before{
        transform: rotate(180deg);
      }
    }
  }
  &.slick-disabled{
    opacity: 0;
  }
  // &.slick-next {
  //   z-index: 10;
  //   cursor: pointer;
  //   -webkit-transition: all .15s ease-out;
  //   transition: all .15s ease-out;
  //   -webkit-user-select: none;
  //   -moz-user-select: -moz- none;
  //   -o-user-select: none;
  //   -ms-user-select: none;
  //   user-select: none;
  //   transform: translate(0,-50%);
  //   &:before {
  //     content: '';
  //     position: absolute;
  //     top: 0;
  //     width: 100%;
  //     height: 100%;
  //     right: 0;
  //     //background: url('#{$assetsPath}/icons/icon-arrow-slider-next.svg') no-repeat 50%;
  //     //transition: all .15s ease-out;
  //     display: block;
  //   }


  // }

  // &.slick-prev {
  //   z-index: 10;
  //   cursor: pointer;
  //   -webkit-transition: all .15s ease-out;
  //   transition: all .15s ease-out;
  //   -webkit-user-select: none;
  //   -moz-user-select: -moz- none;
  //   -o-user-select: none;
  //   -ms-user-select: none;
  //   user-select: none;
  // }
}
.slide-hidden{
  display: none;
}
