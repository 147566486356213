body {
	&.open-modal {
		overflow: hidden;
		padding-right: 18px;
		@include s-1024 {
			padding-right: 0;
		}

		& .modal-bg {
			display: block;
		}
		.c-header{
			padding-right: 18px;
			@include s-1024 {
				padding-right: 0;
			}
		}
	}

	.site {
		position: relative;
	}
	&.page-fixed {
		width: 100%;
		position: fixed;
		left: 0;
		top: 0;
		overflow: hidden;
	}
}

.section-modal {
	display: none;
	justify-content: center;
	z-index: 100;
	position: fixed;
	top: 0;
	left: 0;
	min-height: 100%;
	height: 100vh;
	width: 100%;
	align-items: center;
	&.show-modal {
		display: flex;
	}
	& .modal {
		&-wrap {
			position: relative;
			max-width: 100%;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 20px;
			@include s-768 {
				align-items: flex-start;
			}
		}
		&-block {
			position: relative;
			width: 100%;
			height: 100%;
			justify-content: center;
			z-index: 200;
			display: none;
			align-items: center;
			// display: flex;
			&.open {
				display: flex;
			}
		}
		&-info {
			width: 100%;
			max-width: 1200px;
			margin: 0 auto;
			position: relative;
			z-index: 10;
			padding: 72px 0 32px;
			background-color: var(--color-white);
			max-height: 100%;
			overflow: auto;
			&-wrap{
				display: flex;
				justify-content: space-between;
				padding-left: 32px;
				padding-right: 32px;

				height: 100%;
				@include s-1200{
					flex-wrap: wrap;
				}
			}
			&.bg{
				background-color: var(--color-blue);
				overflow: hidden;
				.item{
					&-title{
						&--title{
							color: var(--color-white);
						}
					}
					&-desc{
						color: var(--color-white);
					}
					&-img{
						position: relative;
						&:after{
							content: '';
							position: absolute;
							left: 47%;
							top: -50%;
							width: 200%;
							height: 200%;
							background-color: var(--color-white);
							z-index: 0;
							border-radius: 35%;
							@include s-1200{
								left: -120%;
								top: -150%;
								width: 350%;
							}
							@include s-768{
								top: -147%;
								border-radius: 100%;
							}
						}
						&-picture{
							z-index: 2;
							position: relative;
						}
					}
				}
			}
			&-desc{
				flex: 0 0 578px;
				max-width: 578px;
				padding-left: 48px;
				@include s-1200{
					flex: 0 0 100%;
					max-width: 100%;
					padding-left: 0;
					text-align: center;
				}
				.item{
					@include s-1200{
						max-width: 570px;
						margin: 0 auto;
					}
					&-title{
						&--title{
							text-transform: capitalize;
						}
					}
					&-desc{
						padding-top: 16px;
					}
					&-btn{
						padding-top: 32px;
						.btn{
							color: var(--color-blue-1)
						}
					}
					&-form{
						padding-top: 32px;
						.form-item{
							&-email{
								position: relative;
								&-wrap{
									position: relative;
								}
								input{
									padding-left: 50px;
									padding-right: 221px;
									@include s-768{
										padding-right: 16px;
									}
								}
							}
							&-actions{
								position: absolute;
								right: 0;
								top: 0;
								max-width: 221px;
								padding: 0;
								@include s-768{
								   position: relative;
								   max-width: 100%;
								}
							}
						}

						.btn{
							max-width: 221px;
							@include s-768{
								max-width: 100%;
							}

						}
					}
				}
			}
			&-img{
				flex: 0 0 38.5%;
				max-width: 421px;
				padding-left: 10px;
				padding-right: 48px;
				@include s-1200{
					flex: 0 0 100%;
					max-width: 100%;
					padding: 0;
					order: -1;
					display: flex;
					justify-content: center;
				}
				.item-img{
					max-width: 363px;
					img{
						max-width: 363px;
						height: auto;
					}
				}
			}

		}
		// &-title {
		// 	display: flex;
		// 	justify-content: center;
		// 	@include s-640 {
		// 		max-width: 170px;
		// 		margin: 0 auto;
		// 		text-align: center;
		// 	}
		// }
		// &-desc {
		// 	padding-top: 10px;
		// 	font-size: 14px;
		// 	text-align: center;
		// }
		&-close {
			position: absolute;
			top: 32px;
			right: 32px;
			width: 40px;
			height: 40px;
			z-index: 20;
			// @include s-1024{
			// 	top: 40px;
			// 	right: 50px;
			// }
			// @include s-640{
			// 	top: 20px;
			// 	right: 24px;
			// }
			& .btn {
				padding: 0;
				font-size: 0;
				width: 40px;
				height: 40px;
				border: 0 none;
				background-color: transparent;
				background-image: url('../icons/close.svg');
				background-repeat: no-repeat;
				background-attachment: scroll;
				background-size: contain;
				background-position: center center;
				cursor: pointer;
			}
		}
	}
	& .form-block {
		padding-top: 30px;
		width: 100%;
		.wpcf7-form {
			& > p {
				display: none;
			}
		}
		.form-item-pair {
			margin: 0;
			padding-left: 0;
			padding-right: 0;
			&-actions {
			}
			&-hidden {
				visibility: hidden;
				opacity: 0;
				height: 0;
			}
		}
		.form-label {
			font-size: 12px;
		}
		.form-item {
			padding-left: 0;
			padding-right: 0;
			&.check-link{
				a{
					color : blue;
				}
			}
		}
		.form-actions {
			padding: 0;
			display: flex;
			justify-content: center;
			padding-left: 0;
			padding-right: 0;
			// padding-left: 10px;
			// padding-right: 10px;
			// @include s-640 {
			// 	padding-left: 0;
			// 	padding-right: 0;
			// }
		}
	}
}
.modal-bg {
	position: absolute;
	display: none;
	width: 100%;
	min-height: 100%;
	height: 100vh;
	z-index: 5;
	top: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.3);
	&.open {
		display: block;
	}
}
