@font-face {
	font-family: 'OutfitRegular';
	src: url('../../assets/fonts/OutfitRegular.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/OutfitRegular.woff') format('woff'),
		url('../../assets/fonts/OutfitRegular.woff2') format('woff2'),
    url('../../assets/fonts/OutfitRegular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'OutfitLight1';
	src: url('../../assets/fonts/OutfitLight1.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/OutfitLight1.woff') format('woff'),
		url('../../assets/fonts/OutfitLight1.woff2') format('woff2'),
		url('../../assets/fonts/OutfitLight1.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'OutfitMedium';
	src: url('../../assets/fonts/OutfitMedium.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/OutfitMedium.woff') format('woff'),
		url('../../assets/fonts/OutfitMedium.woff2') format('woff2'),
		url('../../assets/fonts/OutfitMedium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'OutfitSemiBold';
	src: url('../../assets/fonts/OutfitSemiBold.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/OutfitSemiBold.woff') format('woff'),
		url('../../assets/fonts/OutfitSemiBold.woff2') format('woff2'),
		url('../../assets/fonts/OutfitSemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'OutfitBold';
	src: url('../../assets/fonts/OutfitBold.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/OutfitBold.woff') format('woff'),
		url('../../assets/fonts/OutfitBold.woff2') format('woff2'),
		url('../../assets/fonts/OutfitBold.ttf') format('truetype');

	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
