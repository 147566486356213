.icon-svg{
    background-image: url('../icons/healthcare.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &-healthcare{
        &>.icon-svg{
            background-image: url('../icons/healthcare-color.svg');
        }

        &.colorful{
            &>.icon-svg{
                background-image: url('../icons/healthcare.svg');
            }

        }
        &:hover{
            &>.icon-svg{
                background-image: url('../icons/healthcare-high.svg');
            }

        }
    }
    &-fintech{
        &>.icon-svg{
            background-image: url('../icons/fintech-color.svg');
        }

        &.colorful{
            &>.icon-svg{
                background-image: url('../icons/fintech.svg');
            }

        }
        &:hover{
            &>.icon-svg{
                background-image: url('../icons/fintech-high.svg');
            }

        }
    }
    &-manufacturing{
        &>.icon-svg{
            background-image: url('../icons/manufacturing-color.svg');
        }

        &.colorful{
            &>.icon-svg{
                background-image: url('../icons/manufacturing.svg');
            }

        }
        &:hover{
            &>.icon-svg{
                background-image: url('../icons/manufacturing-high.svg');
            }

        }
    }
    &-realestate{
        &>.icon-svg{
            background-image: url('../icons/realestate-color.svg');
        }
        &.colorful{
            &>.icon-svg{
                background-image: url('../icons/realestate.svg');
            }

        }
        &:hover{
            &>.icon-svg{
                background-image: url('../icons/realestate-high.svg');
            }

        }
    }
    &-automotive{
        &>.icon-svg{
            background-image: url('../icons/automotive-color.svg');
        }

        &.colorful{
            &>.icon-svg{
                background-image: url('../icons/automotive.svg');
            }

        }
        &:hover{
            &>.icon-svg{
                background-image: url('../icons/automotive-high.svg');
            }

        }
    }
    &-construction{
        &>.icon-svg{
            background-image: url('../icons/construction-color.svg');
        }

        &.colorful{
            &>.icon-svg{
                background-image: url('../icons/construction.svg');
            }

        }
        &:hover{
            &>.icon-svg{
                background-image: url('../icons/construction-high.svg');
            }

        }
    }
    &-education{
        &>.icon-svg{
            background-image: url('../icons/education-color.svg');
        }

        &.colorful{
            &>.icon-svg{
                background-image: url('../icons/education.svg');
            }

        }
        &:hover{
            &>.icon-svg{
                background-image: url('../icons/education-high.svg');
            }

        }
    }
    &-b2b{
        &>.icon-svg{
            background-image: url('../icons/b2b-color.svg');
        }

        &.colorful{
            &>.icon-svg{
                background-image: url('../icons/b2b.svg');
            }

        }
        &:hover{
            &>.icon-svg{
                background-image: url('../icons/b2b-high.svg');
            }

        }
    }
    &-governance{
        background-image: url('../icons/governance.svg');
    }
    &-identity{
        background-image: url('../icons/identity.svg');
    }
    &-network{
        background-image: url('../icons/network.svg');
    }
    &-storage{
        background-image: url('../icons/storage.svg');
    }
    &-datacontrols{
        background-image: url('../icons/datacontrols.svg');
    }
    &-logging{
        background-image: url('../icons/logging.svg');
    }
    &-database{
        background-image: url('../icons/database.svg');
    }
    &-application{
        background-image: url('../icons/application.svg');
    }
}
