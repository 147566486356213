:root {
	--font-family: 'OutfitRegular', sans-serif;
	--font-reg: 'OutfitRegular',sans-serif;
	--font-light: 'OutfitLight1',sans-serif;
	--font-med: 'OutfitMedium',sans-serif;
	--font-semi: 'OutfitSemiBold',sans-serif;
	--font-bold: 'OutfitBold';
	--font-w-light: 300;
	--font-w-regular: 400;
	--font-w-medium: 500;
	--font-w-semi: 600;
	--font-w-bold: 700;
}
