:root {
	//Grayscale
	--color-white: #fff;

	--color-grey: #4D4D4D;
	--color-grey-1: #E9ECF4;
	--color-grey-2: #F8F9FB;
	--color-grey-3: #FBF8F4;
	--color-grey-4: #BABABA;
	--color-grey-5: #d9d9d9;
	--color-black: #2C2C2C;

	--color-blue: #091F5B;
	--color-blue-1: #2B6CC7;
	--color-blue-2: #D0E4FF;

	--color-red: #E90000;

}
