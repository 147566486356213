.c-company{
    display: none;
    padding-top: 80px;
    @include s-1200{
        padding-top: 64px;
    }
    @include s-768{
        padding-top: 48px;
    }
    &-wrap{

    }
    &.without-title{
        padding-top: 64px;
        @include s-768{
            padding-top: 48px;
        }
        & .c-company-list{
            padding-top: 0;
        }
    }
    &-top{
        &-row{
            @include s-768{
                flex-wrap: wrap;
            }
        }
        &-col{
            @include s-768{
                max-width: 100%;
                flex: 0 0 100%;
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 16px;
            }
        }
        .item{
            &-label{
                text-transform: uppercase;
            }
            &-btn{
                padding-top: 24px;
            }
        }
    }

    &-list{
        padding-top: 35px;
        margin: 0 auto;
        position: relative;

        max-width: 1264px;
        @include s-1200{
            max-width: 100%;
            padding: 32px 0 0;
        }
        &-solutions{
        padding-left: 32px;
        padding-right: 32px;
        @include s-1200{
            max-width: 100%;
            padding: 32px 0 0;
        }
        }
        &-wrap{
            display: flex;
            margin: 0 -16px;
            @include s-1200{
                margin: 0;
            }
        }
        &-li{
            padding-left: 16px;
            padding-right: 16px;
            min-height: 320px;
            @include s-1200{
                padding-left: 8px;
                padding-right: 8px;
            }
            .item{
                padding: 30px 40px;
                border-radius: 22px;
                border: 2px solid var(--color-grey-1);
                background: var(--color-white);
                height: 100%;
                &-label{
                    &--title{
                        color: var(--color-blue-1);
                        font-size: 56px;
                    }
                }
                &-logo{
                    height: 73px;
                    width: 100%;
                    img{
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
                &-title{
                    padding-top: 24px;

                }
                &-desc{
                    &-pad{
                        padding-top: 16px;
                    }

                }
                &-more{
                    padding-top: 24px;
                }
            }
        }
    }
    &-slider{
        &-nav{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 24px;
        }
    }

    &-row{
        @include s-1200{
            margin: 0 -4px;
        }
        @include s-768{
            flex-wrap:wrap
        }
    }
    &-col{
        max-width: 33.33%;
        flex: 0 0 33.33%;
        @include s-1200{
            padding-left: 4px;
            padding-right: 4px;
        }
        @include s-768{
            max-width: 100%;
            flex: 0 0 100%;
            padding-bottom: 16px;

            min-height: 270px;
        }
        .item{
            padding: 30px 40px;
            border-radius: 22px;
            border: 2px solid var(--color-grey-1);
            background: var(--color-white);
            height: 100%;
            &-label{
                &--title{
                    color: var(--color-blue-1);
                    font-size: 56px;
                }
            }
            &-logo{
                height: 73px;
                width: 100%;
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            &-title{
                padding-top: 24px;

            }
            &-desc{
                &-pad{
                    padding-top: 16px;
                }

            }
            &-more{
                padding-top: 24px;
            }
        }
    }



}

.case-study{
    display: none;
}