.c-footer {
  // position: absolute;
  // left: 0;
  // bottom: 0;

  color: var(--color-white);
  width: 100%;
  background-color: var(--color-blue);
  padding: 48px 0 48px;
  &-wrap {
		// width: 100%;
		// max-width: 1264px;
		margin: 0 -32px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    @include s-1200{
      flex-wrap: wrap;
      margin: 0;
    }
    @include s-1024{
      margin: 0;
    }
	}
  &-item{
    flex: 0 0 25%;
    padding-left: 32px;
    padding-right: 32px;
    @include s-1200{
      flex: 0 0 50%;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 50px;
    }
    @include s-640{
      flex: 0 0 100%;
    }
    &-title{
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 18px;
      font-family: var(--font-med);
      line-height: 130%;
      text-transform: uppercase;
      padding-bottom: 18px;
    }
    &-block{

    }
    ul{

    }
    li{
      padding-top: 10px;
      padding-bottom: 10px;
    }
    a{
      color:var(--color-white);
      font-size: 16px;
      font-family: var(--font-light);
      font-weight: 300;
      line-height: 140%;
      letter-spacing: 0.16px;
    }
  }
  &-top{
    padding-bottom: 32px;
    @include s-1200{
      padding-bottom: 0;
    }
  }
  &-bottom{
    border-top: 1px solid var(--color-grey);
    padding-top: 24px;
    &-wrap{
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include s-1200{
        flex-wrap: wrap;
      }
    }
    &-left{
      display: flex;
      align-items: center;
      @include s-1200{
        flex: 0 0 100%;
        max-width: 100%;
        order: 1;
      }
    }
    &-logo{
      flex: 0 0 146px;
      max-width: 146px;
      display: flex;
      align-items: center;
      .logo{
        display: inline-flex;
      }
    }
    &-copyright{
      padding-left: 16px;
      color:var(--color-white);
      font-size: 16px;
      font-family: var(--font-light);
      line-height: 140%;
      letter-spacing: 0.16px;
    }
    &-menu{
      @include s-1200{
        flex: 0 0 100%;
        max-width: 100%;
      }
      ul{
        display: flex;
        @include s-1200{
          display: block;
        }
      }
      li{
        padding-left: 20px;
        padding-right: 20px;
        @include s-1200{
          padding:10px 0;
        }
      }
      a{
        color:var(--color-white);
        font-size: 16px;
        font-family: var(--font-light);
        line-height: 140%;
        letter-spacing: 0.16px;
      }
    }
    &-social{
      @include s-1200{
        flex: 0 0 100%;
        max-width: 100%;
        padding-bottom: 24px;
      }
    }
  }


  .contacts{
    ul {
      & > li {
        & > a {
          position: relative;
          display: inline-flex;
          align-items: center;
        }
      }
    }
    .icon{
      width: 24px;
      height: 24px;
      object-fit: contain;
      display: block;
    }
    .text{
      display: block;
      padding-left: 16px;
    }
  }
  .social{
    ul {
      display: flex;
      & > li {
        display: inline-flex;
        padding-right: 16px;
        &:last-of-type{
          padding-right: 0;
        }
        & > a {
          position: relative;
          padding: 8px;
        }
      }
    }
    .icon{
      width: 24px;
      height: 24px;
      object-fit: contain;
      display: block;
    }
  }
}
