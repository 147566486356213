.p-home {
	&-content{
		background-color: var(--color-grey-2);
	}
	.c-index {
		height: auto;
		min-height: 640px;
		position: relative;
		@include s-1200{
			min-height: 550px;
		}
		@include s-768{
			min-height: 550px;
		}
		&-content {
			width: 100%;
			position: relative;
			z-index: 2;
			&-wrap {
				width: 100%;
				margin: 0 auto;
				display: flex;
				justify-content: flex-start;
				padding-top: 116px;
				padding-bottom: 130px;
				@include s-1200 {
					justify-content: center;
					padding-top: 56px;
					padding-bottom: 100px;
				}
				@include s-768 {
					padding-top: 40px;
					text-align: center;
				}
			}
			&-col{
				width: 50%;
				@include s-1200 {
					width:100%;
				}
			}
			&-block{
				text-align: left;
				max-width: 550px;
				width: 100%;
				margin: 0;
				@include s-1200 {
					max-width: 100%;
					text-align: center;
				}
				@include s-640 {
					// max-width: 220px;
				}

			}
			.item {
				&-title {
					width: 100%;
					&--title {
						// color: var(--color-pink);
					}
				}
				&-desc {
					padding-top: 16px;
				}
				&-btns {
					padding-top: 48px;
					display: flex;
					justify-content: flex-start;
					@include s-1200{
						justify-content: center;
					}
					@include s-768 {
						flex-wrap: wrap;
					}
				}
				&-btn{
					flex: 0 0 50%;
					max-width: 225px;
					&:first-of-type{
						margin-right: 24px;
						@include s-768 {
							margin-right: 0;
							margin-bottom: 16px;
						}
					}
					@include s-768 {
						flex: 0 0 100%;
						max-width: 100%;
					}
					.btn{
						@include s-768 {
							max-width: 100%;
						}
					}
				}

			}
		}

		&-bg {
			height: 100%;
			width: 100%;

			position: absolute;
			left: 0;
			top: 0;
			z-index: 0;
			img,video{
				width: 100%;
				height: 100%;
				object-fit: cover;
			  }
			.item {
				&-img {
					&-picture{
						display: block;
						width: 100%;
						height: 100%;
						position: relative;
					}
					&--img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}

	.s-solutions{
		padding-top: 80px;
		padding-bottom: 80px;
		@include s-1200{
			padding-top: 64px;
			padding-bottom: 64px;
		}
		@include s-768{
			padding-top: 48px;
			padding-bottom: 48px;
		}
		&-wrap{

		}
		&-list{

		}
		.title-block{
			.item-title{
				text-align: center;
			}
		}
		&-content{
			padding-top: 56px;
			@include s-1200{
				padding-top: 32px;
			}
		}
		&-row{
			padding-top: 100px;
			justify-content: space-between;

			@include s-1200{
				padding-top: 40px;
			}
			&:first-of-type{
				padding-top: 0;
			}
		}
		&-col{
			max-width: 582px;
			flex: 0 0 50%;
			&-img{
				@include s-1200{
					display: none;
				}
			}
			&-desc{
				@include s-1200{
					max-width: 100%;
					flex: 0 0 100%;
				}
			}
		}
		.item{
			&-img{
				img{
					width: 100%;
				}
			}
			&-faq{
				padding-top: 24px;
			}
		}
		&-li{
			.item{
				&-label{
					&--title{

					}
				}
				&-desc{

				}
			}
		}
	}
	.s-implementation{
		padding-top: 80px;
		@include s-1200{
			padding-top: 64px;
		}
		@include s-768{
			padding-top: 48px;
		}
		&-wrap{

		}
		&-top{
			@include s-768{
				text-align: center;
			}
			.item{
				&-label{
					text-transform: uppercase;
				}
				&-btn{
					padding-top: 24px;
				}
			}
		}
		&-img{
			padding-top: 32px;
			max-width: 100%;
			flex: 0 0 100%;
		}
		&-row{
			padding-top: 100px;
			justify-content: space-between;
			flex-wrap: wrap;
			&:first-of-type{
				padding-top: 0;
			}
		}
		&-col{
			max-width: 50%;
			flex: 0 0 50%;
			@include s-768{
				max-width: 100%;
				flex: 0 0 100%;
				padding-left: 0;
				padding-right: 0;
			}
			&-right{
				@include s-768{
					padding-top: 32px;
					order:1;
				}
			}
		}
		.item{
			&-img{
				&-picture{
					img{
						width: 100%;
						object-fit: cover;
					}
				}

			}
		}

	}

	.s-power{
		padding-bottom: 80px;
		@include s-1200{
			padding-bottom: 48px;
		}
		&-wrap{

		}
		&-block{
			text-align: center;
			max-width: 695px;
			margin: 0 auto;
		}
		.item{
			&-title{
				text-align: center;
				&--title{

				}
			}
			&-desc {
				padding-top: 16px;
			}
			&-btns {
				padding-top: 48px;
				display: flex;
				justify-content: center;
				@include s-1024{
					justify-content: center;
				}
				@include s-640 {
					flex-wrap: wrap;
				}
			}
			&-btn{
				flex: 0 0 50%;
				max-width: 225px;
				&:first-of-type{
					margin-right: 24px;
					@include s-640 {
						margin-right: 0;
						margin-bottom: 16px;
					}
				}
				@include s-640 {
					flex: 0 0 100%;
					max-width: 100%;
				}
				.btn{
					@include s-640 {
						max-width: 100%;
					}
				}
			}
		}

	}
}
