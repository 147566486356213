//base styles for project
@import 'normalize';
@import './globals/fonts';
@import './globals/typography';
@import './globals/mixins';
@import './globals/grid';
@import './globals/colors';
@import './globals/base';
@import './globals/icons';
@import './globals/elements';
@import './globals/buttons';
@import './globals/form';
@import './globals/custom-slick';
@import './globals/modal-block';

@import './components/c-header';
@import './components/c-footer';
@import './components/c-index';
@import './components/c-faq';
@import './components/c-industries';
@import './components/c-download';
@import './components/c-compliance';
@import './components/c-reviews';
@import './components/c-company';
@import './components/c-successes';
@import './components/c-contact';
@import './components/c-application';
@import './components/_c-acquaintance';
// @import './components/c-cookies';

// Pages
@import './pages/p-page';
@import './pages/p-home';
@import './pages/p-aiml' ;
@import './pages/p-solutions' ;
@import './pages/_p-industry' ;
@import './pages/_p-about' ;
@import './pages/_p-client' ;
@import './pages/_p-case' ;
@import './pages/_p-contact' ;
@import './pages/_p-pricing';
.c-tab-item-block{
    padding-right: 0 !important;
}