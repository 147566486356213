.btn{
	cursor: pointer;
}

.btn-border {
	background-color: var(--color-white);
	font-family: var(--font-semi);
	border: 2px solid var(--color-blue-1);
	border-radius: 40px;
	color: var(--color-black);
	padding: 14px;
	width: 100%;
	max-width: 220px;
	height: 50px;
	text-transform: uppercase;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 18px;
	line-height: normal;
	letter-spacing: 0.18px;
	text-transform: capitalize;

	&:hover {
		color:var(--color-blue-1);
	}
	@include s-768{
		max-width: 100%;
	}
}

.btn-blue {
	background-color: var(--color-blue-1);
	font-family: var(--font-semi);
	border: 2px solid var(--color-blue-1);
	border-radius: 40px;
	color: var(--color-white);
	padding: 14px;
	width: 100%;
	max-width: 220px;
	height: 50px;
	text-transform: uppercase;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 18px;
	line-height: normal;
	letter-spacing: 0.18px;
	text-transform: capitalize;
	&:hover {
		box-shadow: 8px 4px 6.5px 0px rgba(0, 0, 0, 0.25) inset;
	}
	@include s-768{
		max-width: 100%;
	}
}

.btn-link {
	background-color: transparent;
	font-family: var(--font-semi-mon);
	color: var(--color-blue-1);
	padding: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
	font-size: 20px;
	cursor: pointer;
}
.btn-back {
	background-color: transparent;
	font-family: var(--font-semi-mon);
	color: var(--color-blue-2);
	padding: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
	font-size: 20px;
	cursor: pointer;
}
.btn-icon {
	background-color: var(--color-grey);
	font-size: calcFluidFontSize(24, 28, 1920, 2560);
	font-family: var(--font-regular);
	font-weight: var(--font-w-regular);
	color: var(--color-white);
	padding: 10px;
	width: 100%;
	max-width: 420px;
	height: 80px;
	text-transform: uppercase;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
	@include s-1920 {
		max-width: 360px;
		font-size: calcFluidFontSize(20, 24, 1440, 1920);
		height: 68px;
	}
	@include s-1440 {
		max-width: 280px;
		font-size: calcFluidFontSize(16, 20, 1024, 1440);
		height: 52px;
	}
	@include s-1024 {
		font-size: 16px;
		height: 44px;
		max-width: 240px;
	}
	@include s-768 {
	}
	@include s-640 {
		max-width: 335px;
		font-size: 14px;
	}
	&:hover {
	}
}
