form {
  .form-item-pair {
    // display: flex;
    // flex-wrap: nowrap;
    // margin: 0 -10px;
    flex: 0 0 100%;
    @include s-1200{
      margin: 0;
      padding: 0;
    }
  }


  label {
    color: var(--color-black);
    padding-left: 16px;
    padding-bottom: 4px;
    font-family: var(--font-med);
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.16px;
    display: inline-flex;
    & .red{
      color: var(--color-red);
    }
  }

  .form-item {
    position: relative;
    max-width: 100%;
    width: 100%;
    // padding-bottom: 12px;
    padding-bottom: 16px;

    & > label {
      //opacity: 0;
    }

    &.error{
      input[type="number"],
      input[type="text"],
      input[type="tel"],
      input[type="email"],
      input[type="url"],
      input[type="date"],
      input[type="password"],
      select,
      textarea {
        border-radius: 32px;
        border: 2px solid var(--color-grey-1);
        background: var(--color-grey-2);
        &:focus {
          // border-color: var(--color-red);
          // box-shadow: 0 0 0 2px rgba(220,53,69, .3);
        }
      }
    }
    &-actions{
      &-wrap{
        padding-top: 24px;
      }
      .btn{
        max-width: 100%;
      }
    }
  }

  input[type="text"],
  input[type="password"] {
    padding: 10px 15px;
    color: var(--color-black);
    background: #f5f1f1;
    outline: none;
    transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  }

  input[type=submit] {
    font-size: 17px;
    //border: 1px solid var(--color-black);
    //background-color: var(--color-black);
    color: var(--color-white);
    width: 100%;
    text-transform: uppercase;
    transition : all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
    border-radius: 0;
    cursor: pointer;
    @include s-1200{
      font-size: 14px;
    }
    @include s-768{
      font-size: 11px;
    }
  }

  input[type="number"],
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="url"],
  input[type="date"],
  input[type="password"],
  textarea {
    flex: 0 0 100%;
    width: 100%;
    font-size: 18px;
    height: 50px;
    color: var(--color-grey);
    padding: 11px 16px;
    border-radius: 32px;
    border: 2px solid var(--color-grey-1);
    background: var(--color-grey-2);
    transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
    &::-webkit-input-placeholder {
      color: var(--color-grey-4);
      opacity: 1;
      font-size: 18px;
    }

    &::-moz-placeholder {
      color: var(--color-grey-4);
      opacity: 1;
      font-size: 18px;
    }

    &:-moz-placeholder {
      color: var(--color-grey-4);
      opacity: 1;
      font-size: 18px;
    }

    &:-ms-input-placeholder {
      color: var(--color-grey-4);
      opacity: 1;
      font-size: 18px;
    }

    &:focus {
      &::-webkit-input-placeholder {
        color: transparent;
      }

      &::-moz-placeholder {
        color: transparent;
      }

      &:-moz-placeholder {
        color: transparent;
      }

      &:-ms-input-placeholder {
        color: transparent;
      }
    }

  }
  input.email-icon{
    padding-left: 50px;
    background-image: url('../icons/mail-hidden.svg');
    background-position: 16px center;
    background-repeat: no-repeat;
    background-size: 24px;
    &:focus,&:not(:placeholder-shown) {
      background-image: url('../icons/mail-default.svg') ;
    }
  }
  textarea {
    height: 105px;
    border-radius: 8px;
  }
  // .custom-select{
  //   position: relative;
  //   &:after{
  //     content: '';
  //     width: 30px;
  //     height: 30px;
  //     background: url('../icons/arrow-bottom.svg') no-repeat center center  ;
  //     background-size: contain;
  //     position: absolute;
  //     right: 10px;
  //     top: 50%;
  //     transform: translateY(-8px);
  //     pointer-events: none;
  //     z-index: 1;
  //   }
  //   &:focus{
  //     &:after{
  //       transform: rotate(180deg) translateY(-8px);
  //     }
  //   }
  // }
  // select {
  //   appearance: none;
  //   -webkit-appearance: none;
  //   -moz-appearance: none;
  //   padding: 11px 60px 11px 16px;
  //   flex: 0 0 100%;
  //   width: 100%;
  //   font-size: 18px;
  //   height: 50px;
  //   color: var(--color-grey);
  //   border-radius: 32px;
  //   border: 2px solid var(--color-grey-1);
  //   background: var(--color-grey-2);
  //   transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
  //   background-color: var(--color-white);
  //   background-image: none;

  //   &::-webkit-input-placeholder {
  //     color: var(--color-grey-4);
  //     opacity: 1;
  //     font-size: 18px;
  //   }

  //   &::-moz-placeholder {
  //     color: var(--color-grey-4);
  //     opacity: 1;
  //     font-size: 18px;
  //   }

  //   &:-moz-placeholder {
  //     color: var(--color-grey-4);
  //     opacity: 1;
  //     font-size: 18px;
  //   }

  //   &:-ms-input-placeholder {
  //     color: var(--color-grey-4);
  //     opacity: 1;
  //     font-size: 18px;
  //   }
  //   // &:focus {
  //   //   border-color: var(--color-blue-2);
  //   //   box-shadow: 0 0 0 2px rgba(28, 105, 212, .3);
  //   // }
  //   // @include s-768{
  //   //   padding-right: 35px;
  //   // }
  //   option{
  //     padding-top: 85px;
  //     padding-bottom: 8px;
  //     font-size: 18px;
  //     line-height: 150%;
  //     &:hover{
  //       color: var(--color-blue-2);
  //     }
  //   }
  // }
  .error-text{
    position: absolute;
    bottom: 0;
    left: 16px;
    color: var(--color-red);
    font-family: var(--font-light);
    visibility: hidden;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.16px;
    &.open{
      visibility: visible;
    }
  }
  .form-message-count{
    position: absolute;
    bottom: 0;
    left: 16px;
    font-family: var(--font-light);
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.16px;
    line-height:140%;
    &.full{
      color: var(--color-red);

    }
  }

  .custom-select{
    label{
      display: block;
    }
    .select2-container {
      width: 100% !important;
    }
    .select2-container .select2-selection--single {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding: 11px 60px 11px 16px;
      flex: 0 0 100%;
      width: 100%;
      font-size: 18px;
      height: 50px;
      color: var(--color-grey);
      border-radius: 32px!important;
      border: 2px solid var(--color-grey-1);
      background-image: none;
      transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
      background-color: var(--color-grey-2);
      position: relative;
    }

    .select2-container .select2-selection--single{
      width: 100% !important;
      &:after {
        content: '';
        position: absolute;
        right: 16px;
        top: 50%;
        width: 30px;
        height: 30px;
        background: url('../icons/arrow-bottom.svg') no-repeat center center  ;
        background-size: contain;
        transform: translateY(-50%) rotate(0deg);
        transition: transform 0.3s ease;
        pointer-events: none;
      }
    }
    .select2-container.select2-container--open{
      .select2-selection--single{
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    .select2-container .select2-selection--single .select2-selection__arrow {
      display: none;
    }

    .select2-container--default .select2-selection--single .select2-selection__placeholder {
      color: var(--color-grey-4);
      font-size: 18px;
    }


    .select2-container--default .select2-results__option {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 18px;
      line-height: 150%;
      background-color: transparent !important;
    }

    .select2-container--default .select2-results__option--highlighted {
      color: var(--color-blue-2);
      font-weight: bold;
    }


    // .select2-dropdown {
    //   border: 2px solid var(--color-grey-1);
    //   border-radius: 16px;
    // }
    // .select2-container .select2-dropdown {
    //   border-radius: 8px !important;
    // }




  }

}
.legal{
  a{
    color: var(--color-blue-1);
  }
}
body{
  .select2-container .select2-results__option--highlighted {
    background-color: transparent !important;
    color: var(--color-blue-1) !important;
    text-shadow: 0 0 0.65px var(--color-blue-1), 0 0 0.65px var(--color-blue-1);
  }
  .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable{
  background-color: transparent !important;
  color: var(--color-blue-1) !important;
  text-shadow: 0 0 0.65px var(--color-blue-1), 0 0 0.65px var(--color-blue-1);
  }
  .select2-container--default .select2-results__option--selected{
    background-color: transparent !important;
    color: var(--color-blue-1) !important;
    text-shadow: 0 0 0.65px var(--color-blue-1), 0 0 0.65px var(--color-blue-1);
  }
  .select2-container--open .select2-dropdown--below{
    margin-top: 5px;
    border-top: 1px solid #aaa!important;
    border-radius: 8px!important;
    background: var(--color-white);
  }
  .select2-container--default .select2-selection--single .select2-selection__rendered{
    color: var(--color-black);
  &[title="Select company size"] {
      color: var(--color-grey-4);
    }
    &[title="Select industry"] {
      color: var(--color-grey-4);
    }
    &[title="Select solutions"] {
      color: var(--color-grey-4);
    }
   }
}
