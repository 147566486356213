body {
  color: var(--color-black);
  font-family: var(--font-reg);
  line-height: 150%;
  font-size: 18px;
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 320px;
  min-height: 100%;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;




  // &.modal-open {
  //   overflow: hidden;

  //   & .site {
  //     z-index: 13;
  //   }
  // }
}
.site-main{
  padding-top: 80px;
}
* {
  outline: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}



a {
  text-decoration: none;
  color: var(--color-black);
}
