.c-contact{
    padding-top: 80px;
    @include s-1200{
        padding-top: 64px;
    }
    @include s-768{
        padding-top: 48px;
    }
    &-wrap{
        background-color: var(--color-grey-1);
        border-radius: 22px;
        padding: 80px 80px 64px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        @include s-1200{
            flex-wrap: wrap;
        }
        @include s-768{
            padding: 32px 32px 16px;
        }
    }
    &-left{
        flex: 0 0 570px;
        max-width: 570px;
        @include s-1200{
            text-align: center;
        }
        @include s-768{
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
    &-img{
        flex: 1;
        padding-left: 98px;
        display: flex;
        align-items: center;
        @include s-1200{
          padding-left: 0;
          padding-top: 48px;
          justify-content: center;
        }
        img{
            width: 100%;
        }
    }
    .item{
        &-title{
        }
        &-desc{
            padding-top: 16px;

        }
        &-form{
            padding-top: 32px;
            .form-item{
                &-email{
                    position: relative;
                    &-wrap{
                        position: relative;
                    }
                    input{
                        padding-left: 50px;
                        padding-right: 221px;
                        @include s-768{
                            padding-right: 16px;
                        }
                    }
                }
                &-actions{
                    position: absolute;
                    right: 0;
                    top: 0;
                    max-width: 221px;
                    padding: 0;
                    @include s-768{
                       position: relative;
                       max-width: 100%;
                    }
                }
            }

            .btn{
                max-width: 221px;
                @include s-768{
                    max-width: 100%;
                }

            }
        }
        &-img{
            width: 190px;
            height: 190px;
            @include s-768{
              width: 140px;
              height: 140px;
            }
            &--img{
                width: 100%;
                height: 100%;
                height: auto;
                object-fit: cover;
            }
        }
        &-info{
            padding-left: 40px;
            max-width: 184px;
            @include s-768{
              padding-left: 16px;
            }
        }
        &-name{

        }
        &-status{
            padding-top: 4px;
            padding-bottom: 12px;

        }
        &-link{
            width: 24px;
            height: 24px;
            display: block;
            .icon{
                width: 100%;
                height: 100%;
                display: block;
            }
        }
    }

}
