.p-solutions{
	&-content{
		background-color: var(--color-grey-2);
	}
	.c-index {
		height: auto;
		min-height: 640px;
		position: relative;
		&-content {

			width: 100%;
			position: relative;
			z-index: 2;
			&-wrap {
				width: 100%;
				margin: 0 auto;
				display: flex;
				justify-content: center;
				padding-top: 116px;
				@include s-1200 {
					justify-content: center;
					padding-top: 56px;
				}
				@include s-768 {
					padding-top: 40px;
					text-align: center;
				}
			}
			&-col{
				width:100%;
			}
			&-block{
				text-align: center;
				max-width: 803px;
				width: 100%;
                margin: 0 auto;
				@include s-1200 {
					max-width: 100%;
				}


			}
			.item {
				&-title {
					width: 100%;
					&--title {
						// color: var(--color-pink);
					}
				}
				&-desc {
					padding-top: 16px;
				}
				&-btns {
					padding-top: 48px;
					display: flex;
                    justify-content: center;
					@include s-768 {
						flex-wrap: wrap;
					}
				}
				&-btn{
					flex: 0 0 50%;
					max-width: 225px;
					&:first-of-type{
						margin-right: 24px;
						@include s-768 {
							margin-right: 0;
							margin-bottom: 16px;
						}
					}
					@include s-768 {
						flex: 0 0 100%;
						max-width: 100%;
					}
					.btn{
						@include s-768 {
							max-width: 100%;
						}
					}
				}

			}
		}

		&-bg {
			height: 100%;
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 0;
			.item {
				&-img {
					&-picture{
						display: block;
						width: 100%;
						height: 100%;
						position: relative;
					}
					&--img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}

	}
    .c-successes{
        position: relative;
        z-index: 2;
        padding-top: 100px;
        padding-bottom: 60px;
		@include s-1200{
			padding-bottom: 48px;
		}
    }
	.s-solutions{
		padding-top: 80px;
		padding-bottom: 80px;
		@include s-1200{
			padding-top: 48px;
			padding-bottom: 48px;
		}
		&-wrap{

		}
		&-list{

		}
		.title-block{
			.item-title{
				text-align: center;
			}
		}
		&-content{
			padding-top: 56px;
		}
		&-row{
			padding-top: 100px;
			justify-content: space-between;
			&:first-of-type{
				padding-top: 0;
			}
		}
		&-col{
			max-width: 582px;
			flex: 0 0 50%;
			&-img{
				@include s-1200{
					display: none;
				}
			}
			&-desc{
				@include s-1200{
					max-width: 100%;
					flex: 0 0 100%;
				}
			}
		}
		.item{
			&-img{
				img{
					width: 100%;
				}
			}
			&-faq{
				padding-top: 24px;
			}
		}
		&-li{
			.item{
				&-label{
					&--title{

					}
				}
				&-desc{

				}
			}
		}
	}
}
