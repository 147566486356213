.p-client{
	&-content{
		background-color: var(--color-grey-2);
	}
	.c-index {
		&-content {
			&-block{
				text-align: center;
				max-width: 890px;
				width: 100%;
				margin: 0 auto;
				@include s-1200 {
					max-width: 100%;
				}
			}

		}

	}
	.c-compliance{
		padding-top: 124px;
		padding-bottom: 60px;
		background-color: transparent;
		position: relative;
		z-index: 2;
		@include s-768{
			padding-top: 32px;
		}
	}
	.c-contact{
		padding-top: 80px;
		padding-bottom: 80px;
		@include s-1200{
			padding-top: 64px;
			padding-bottom: 64px;
		}
		@include s-768{
			padding-top: 48px;
			padding-bottom: 48px;
		}
	}
}
