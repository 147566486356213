.p-contact {
	&-content{
		background-color: var(--color-grey-2);
	}
	// .c-header {
	// 	&-logo {
	// 		&-item {
	// 		}
	// 		.logo {
	// 			&-img {
	// 			}
	// 			&-svg{
	// 				path{
	// 					fill: var(--color-pink);
	// 				}
	// 			}
	// 		}
	// 	}
	// 	&-menu {
	// 		& ul {
	// 			& > li {
	// 				& > a {
	// 					opacity: 0.45;
	// 					color: var(--color-pink);
	// 					&:hover{
	// 						color: var(--color-pink);
	// 						opacity: 1;
	// 						text-shadow: 0 0 0.65px var(--color-pink), 0 0 0.65px var(--color-pink);
	// 					}
	// 				}
	// 				&.current-menu-item {
	// 					& > a {
	// 						color: var(--color-pink);
	// 						opacity: 1;
	// 						text-shadow: 0 0 0.65px var(--color-pink), 0 0 0.65px var(--color-pink);
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// 	&-btns {
	// 		& .burger {
	// 			.br {
	// 				background-color: var(--color-pink);
	// 			}
	// 		}
	// 	}
	// 	&-mobile {
	// 		&-bg{
	// 			background-color: var(--color-red);
	// 		}
	// 		&-wrap {
	// 			// background-color: var(--color-red);

	// 		}
	// 		&-top {
	// 			& .menu,
	// 			& ul {
	// 				text-align: center;
	// 				& > .menu-item {
	// 					& > a {
	// 						color: var(--color-pink);
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }
	.p-page-content{
		padding-top: 140px;
		padding-bottom: 140px;
		height: 100vh;
		min-height: 768px;
	}
	.s-contact {
		position: relative;
		z-index: 2;
		padding-top: 80px;
		padding-bottom: 80px;
		@include s-1200{
			padding-top: 48px;
			padding-bottom: 48px;
		}
		&-wrap {
			// display: flex;
			// align-items: center;
			// justify-content: center;
			// margin: 0 auto;
		}
		&-row{
			justify-content: space-between;
			@include s-768{
				flex-wrap: wrap;
			}
		}
		&-form{
			flex: 0 0 656px;
			max-width: 656px;
			@include s-1200{
				flex: 0 0 457px;
				max-width: 457px;
			}
			@include s-768{
				flex: 0 0 100%;
				max-width: 100%;
			}
			&-wrap{
				background-color: var(--color-white);
				padding: 40px;
				border-radius: 8px;
				box-shadow: 6px 13px 16px 0px rgba(108, 108, 108, 0.25);
				@include s-1200{
					padding: 40px 24px;
				}
			}
			.item{
				&-desc{
					padding-top: 8px;
					padding-bottom: 24px;
					color: var(--color-grey);
				}
				.legal{
					padding-top: 15px;
				}
				form textarea{
					min-height: 105px;
				}

			}
		}
		&-text{
			flex: 0 0 476px;
			max-width:  476px;
			padding-top: 40px;
			padding-bottom: 40px;
			@include s-1200{
				flex: 0 0 297px;
				max-width:  297px;
			}
			@include s-768{
				flex: 0 0 100%;
				max-width: 100%;
			}
			.item{
				&-desc{
					padding-top: 8px;
					padding-bottom: 24px;
					color: var(--color-grey);
					font-size: 20px;
					font-family: var(--font-med);
					line-height: 150%;
				}
				&-list{
					&-contacts{
						a{
							color: var(--color-blue-1);
							margin-left: 5px;
						}
					}
					&-li{
						&:last-of-type{
							padding-top: 16px;
						}
						.el-text-help{
							color: var(--color-blue);
						}
						.el-text{
							color: var(--color-grey);
						}

					}
				}
			}
		}


	}
	.c-faq{
		padding-top: 80px;
		padding-bottom: 80px;
		@include s-1200{
			padding-top: 48px;
			padding-bottom: 48px;
		}

	}

}
