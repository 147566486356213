.c-application{
    padding-top: 80px;
    padding-bottom: 80px;
    @include s-1200{
        padding-top: 64px;
        padding-bottom: 64px;
    }
    @include s-768{
        padding-top: 48px;
        padding-bottom: 48px;
    }
    &-wrap{

    }
    &-list{

    }
    .title-block{
        text-align: center;

    }
    &-content{
        padding-top: 56px;
    }
    &-row{
        padding-top: 100px;
        justify-content: space-between;
        @include s-1200{
            flex-wrap: wrap;
            padding-top: 32px;
        }
        &:first-of-type{
            padding-top: 0;
        }
    }
    &-col{
        max-width: 582px;
        flex: 0 0 50%;
        @include s-1200{
            max-width: 100%;
            flex: 0 0 100%;
        }
        &-desc{
            @include s-1200{
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
    }
    .c-faq{
        padding-top: 0;
        .c-tab-item-block{
            padding-right: 0;
        }
    }
    .item{
        &-img{
            img{
                width: 100%;
            }
        }
        &-faq{
            padding-top: 24px;
        }
    }


}
