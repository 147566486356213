.c-successes{
    padding-top: 60px;
    &-wrap{

    }

    &-top{
        text-align: center;
        padding-bottom: 32px;
    }
    &-list{
        @include s-1200{
            flex-wrap: wrap;
        }
    }
    &-li{
        @include s-1200{
            flex:0 0 50%;
            max-width: 50%;
            padding-bottom: 24px;
        }
        @include s-768 {
            flex:0 0 100%;
            max-width: 100%;
            padding-bottom: 32px;
            &:last-of-type{
                padding-bottom: 0;
            }
        }

        .item{
            @include s-768 {
                text-align: center;
            }
            &-label{
                &--title{

                }
            }
            &-desc{

            }
        }
    }
}
