.c-header {
	left: 0;
	position: fixed;
	top: 0;
	// transition: height 0.3s linear;
	transition: box-shadow 0.3s linear;
	width: 100%;
	z-index: 13;
	&-desk{
		z-index: 110;
		position: relative;
		background-color: var(--color-white);
	}
	&-wrap {
		display: flex;
		flex-wrap: nowrap;
		width: 100%;
		max-width: 1264px;
		padding: 0 32px;
		margin: 0 auto;
		justify-content: space-between;
		height: 78px;
		align-items: center;

		position: relative;

		// @include s-1200{
		// 	box-shadow: 2px 6px 19.8px 0px rgba(119, 119, 119, 0.25);
		// }

	}
	&.header-fixed{
		box-shadow: 2px 6px 19.8px 0px rgba(119, 119, 119, 0.25);

	}

	&-logo {
		width: 100%;
		&-item {

			display: block;
			position: relative;
		}
		.logo {
			&-img {
				display: block;
				width: 100%;
				height: auto;
			}
			&-svg{
				path{
					fill: var(--color-red);
				}
			}
		}
	}
	&-left{
		max-width: 204px;
		flex: 0 0 204px;
	}
	&-right {
		display: flex;
		flex:1;
		@include s-1200{
			display: none;
		}
		.item{
			&-btn{
				flex: 0 0 220px;
				max-width: 220px;
				display: flex;
				align-items: center;
			}
		}
	}
	&-menu {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		padding: 0 40px;
		@include s-1200 {
			display: none;
		}

		&>ul {
			display: flex;
			justify-content: flex-end;
			width: 100%;
			& > li {
				& > link {
				}
			}
			.menu-item{
				&>.link {
					line-height: 150%;
					transition: none;
					font-size: 18px;
					position: relative;
					display: inline-flex;
					align-items: center;
					padding: 24px;
					height: 100%;
				}

			}
			& > li {

				display: inline-flex;
				align-items: center;
				cursor: pointer;

				&:hover {
					&  .link {
						}
					.icon-menu{
						transform:rotate(0deg);
						transition: transform 0.3s ease-in-out;
					}
				}

				&.active {
					&>.link {
						&:after {
							height: 4px;
							background-color: var(--color-blue-1);
							content: '';
							left: 0;
							bottom: 0;
							position: absolute;
							width: 100%;
						}

					}
					&>.text{
						font-family: var(--font-med);
					}
					&>.icon-menu{
						transform:rotate(180deg);
					}
				}
			}
		}
		.parent{
			display: inline-flex;
			align-items: center;
			.menu-child{
				display: none;
			}
			&:hover{
				&>.menu-child{
					display: block;
				}
			}
			& .text{
				padding-right: 8px;
			}
		}
		.menu{
			&-child{
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				top: 78px;
				width: 100%;
				padding-top: 2px;
				margin: 0 auto;
				&.solutions{
					max-width: 1090px;
				}
				&.company{
					max-width: 760px;
				}
				&-wrap{
					display: flex;
					border-radius: 8px;
					overflow: hidden;
					// background-color: var(--color-white);
					margin: 0 auto;
					max-width: 100%;
				}
				&.company{
					.menu-child-col{
						max-width: 380px;
						flex: 0 0 50%;
						&.bg{
							background-color: var(--color-blue);
						}
					}
				}
				&-col{
					padding:24px 40px;
					flex:  0 0 36%;
					max-width: 36%;
					background-color: var(--color-white);
					.item{
						&-top{
							display: inline-flex;
							border-bottom: 1px solid var(--color-grey-1);
							padding-top: 10px;

							align-items: center;
							max-width: 100%;
							width: 100%;
							justify-content: space-between;
						}
						&-title{

						}

						&-bottom{

						}


						&-video{
							& >a{
								display: block;

							}
							span{
								display: block;
								color: var(--color-white);

							}
							.play-button{
								width: 45px;
								height: 45px;
							}
							.item {
								&-img{
									width: 100%;
									height: 170px;
									img{
										object-fit: cover;
										width: 100%;
										height: 100%;
									}
								}
								&-title{
									padding-top: 16px;
								}
								&-desc{
									padding-top: 8px;
								}
							}
						}
					}
					ul{
						li{
							padding-top: 8px;
							padding-bottom: 8px;
							&.active{
								a{
									// text-shadow: 0 0 0.65px var(--color-blue-1), 0 0 0.65px var(--color-blue-1);
									font-family: var(--font-semi);
									color: var(--color-blue-1);
								}
							}
						}
						a{
							font-size: 16px;
							font-family: var(--font-light);
							color:var(--color-grey);
							font-weight: 300;
							line-height: 140%;
							letter-spacing: 0.16px;
						}
					}
					&-last{
						flex:  0 0 28%;
						max-width: 311px;
						background-color: var(--color-blue);
						border-radius: 0 8px 8px 0;
						.item{
							&-top{
								padding-bottom: 10px;
							}
							&-title{
								color: var(--color-white);
								padding-right: 0;
							}

							&-bottom{

							}

						}
						ul{
							li{
								padding-top: 8px;
								padding-bottom: 8px;
								&.active{
									a{
										// text-shadow: 0 0 0.8px var(--color-blue-2), 0 0 0.8px var(--color-blue-2);
										font-family: var(--font-semi);
										color: var(--color-blue-2);
									}
								}
							}
							a{
								color: var(--color-white);
							}
						}
					}
				}

			}
		}
	}
	&-btns {
		display: none;
		@include s-1200 {
			display: block;
		}
		.btn-menu{
			background-color: transparent;
			border: none;
			padding: 0;
			.icon-open{
				display: block;
			}
			.icon-close{
				display: none;
			}

			&.open{
				.icon-open{
					display: none;
				}
				.icon-close{
					display: block;
				}

			}
		}

	}
	&-mobile {
		position: fixed;
		top: 0;
		right: 0;
		min-height: 100vh;
		height: 100%;
		width: 100%;
		justify-content: flex-end;
		z-index: 100;
		display: flex;
		overflow: hidden;
		visibility: hidden;
		// opacity: 0;
		z-index: 100;
		display: none!important;
		@include s-1200 {
			display: flex!important;
		}
		&-bg {
			position: fixed;
			top: 0;
			left: 0;
			min-height: 100vh;
			height: 100%;
			width: 100%;
			background-color: var(--color-white);
			justify-content: flex-end;
			// opacity: 0;
			visibility: hidden;
			z-index: 103;
			display: flex;
			&.open{
				// opacity: 1;
				visibility: visible;
			}
		}

		&-wrap {
			width: 100%;
			max-width: 100%;
			display: block;
			height: 100%;
			overflow: auto;
			transform: translate(0, 0);
			// padding: 78px 32px 0;
			padding: 78px 0 0;
			position: relative;
			z-index: 104;
			background-color: var(--color-white);
			@include s-768{
				// padding: 78px 16px 0;
			}
		}
		&-top {
			.item{
				padding-top: 16px;
				&:first-of-type{
					padding-top: 0;
				}
				&-menu{
					padding: 16px;
					ul{
						padding-top: 16px;
						&:first-of-type{
							padding-top: 0;
						}
						li{

						}
						a{
							padding-top: 12px;
							padding-bottom: 12px;
							color: var(--color-black);
							font-family: var(--font-med);
							text-transform: uppercase;
							line-height: 130%;
							font-size: 18px;
							display: block;

						}
					}
				}
				&-tab{
					ul{
						padding-top: 16px;
							li{

						}
						a{
							padding-top: 12px;
							padding-bottom: 12px;
							color: var(--color-black);
							line-height: 130%;
							font-size: 18px;
							display: block;

						}
					}
				}
				&-top{
					padding-top: 11px;
					padding-bottom: 11px;
				}
				&-title{
					font-size: 16px;
					font-family: var(--font-light);
					line-height: 140%;
					letter-spacing: 0.16px;
					color: var(--color-grey);
				}
				&-btn{
					padding: 16px;
					.btn{
						max-width: 100%;
					}
				}

			}
			.c-mob{
				&-menu{

				}
				&-item {
					padding: 0;
					transition: padding 0.4s ease-in-out;
					&.hidden{
					  display: none;
					}
					&.active {
						& .c-mob-item-title{
							color: var(--color-blue-1);
							// font-family: var(--font-semi);
							text-shadow: 0 0 0.65px var(--color-blue-1), 0 0 0.65px var(--color-blue-1);
						}
					  & .c-mob-item-title::after {
						transform: translateY(-50%) rotate(180deg);
					  }
					  & .c-mob-item-block{

						ul{
							padding: 16px;
						}
					  }
					}
					&-wrap {
					  position: relative;

					}
					&-title {
					  cursor: pointer;
					  font-family: var(--font-semi-mon);
					  font-size: 20px;
					  line-height: 150%;
					  padding: 16px;
					  position: relative;
					  &:after {
						content: '';
						position: absolute;
						top: 50%;
						right: 16px;
						width: 25px;
						height: 25px;
						transform: translateY(-50%) rotate(0deg);
						transition: all ease-in-out 0.4s;
						background: url('../icons/arrow-bottom.svg') no-repeat center center  ;
						background-size: contain;
						z-index: 10;
					  }


					}
					&-block {
						background-color: var(--color-grey-2);
					  height: 0;
					  overflow: hidden;
					  font-size: 18px;
					  line-height: 24px;
						  max-width: 100%;

						  ul{
							padding:16px;
						  }
					}
					&-desc {
					  font-size: 16px;
					  padding-left: 20px;
					  &--desc {

					  }

					}

				  }
			}

		}
		&-bottom{
			padding-top: 16px;
			padding-bottom: 90px;
			.item{
				&-video{
					background-color: var(--color-blue);
					padding: 32px;
					@include s-768{
						padding: 24px;
					}
					& >a{
						display: block;

					}
					.play-button {
						width: 45px;
						height: 45px;
					  }
					span{
						display: block;
						color: var(--color-white);

					}
					.item {
						&-img{
							width: 100%;
							height: auto;
							img{
								object-fit: cover;
								width: 100%;
								height: 100%;
							}
						}

						&-title{
							padding-top: 16px;
						}
						&-desc{
							padding-top: 8px;
						}
					}
				}
			}
		}
	}
}
