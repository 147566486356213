.c-acquaintance {
    padding-top: 80px;
    @include s-1200{
        padding-top: 64px;
    }
    @include s-768{
        padding-top: 48px;
    }
    &-wrap{
        // max-width: 1512px;
        // margin: 0 auto;
    }
    &-tabs{
        &-tab{

        }
    }
    .underline {
        position: absolute;
        bottom: 0;
        width: 170px;
        height: 4px;
        background-color: var(--color-blue-1);
        transition: width 0.3s ease;
      }

      .c-acquaintance-tabs-block {
        position: relative;
      }
    &-top{
        text-align: center;
    }
    &-tabs{
        padding-top: 56px;
        @include s-1200{
            overflow: hidden;
        }
        @include s-768{
            display: none;
        }
        &-block{
            max-width: 1200px;
            width: 100%;
            margin: 0 auto;
            @include s-1200{
                padding-left: 32px;
                overflow: auto;
            }
        }
        &-wrap{
            width: 100%;
            display: flex;

            justify-content: space-between;
            border-bottom: 1px solid var(--color-grey-1);

        }
        &-tab{
            text-align: center;
            font-size: 16px;
            font-family: var(--font-light);
            cursor: pointer;
            padding: 16px 8px;
            position: relative;
            flex: 0 0 170px;
            max-width: 170px;
            letter-spacing: 0.16px;
            line-height: 140%;
            &.active,&:hover{
                text-shadow: 0 0 0.65px var(--color-black), 0 0 0.65px var(--color-black);
                // &:after {
                //     height: 4px;
                //     background-color: var(--color-blue-1);
                //     content: '';
                //     left: 0;
                //     bottom: 0;
                //     position: absolute;
                //     width: 100%;
                // }
            }

        }
    }
    &-list{
        padding-top: 56px;
        @include s-1200{
           padding-top: 48px;
        }
        @include s-768{
            display: none;
        }
        &-wrap{

        }
        &-ul{
            display: flex;
            flex-wrap: wrap;
        }
        &-li{
            display: none;
            &.active{
                display: block;
            }
        }
    }
    &-faq{
        display: none;
        @include s-768{
            display: block;
        }
        .c-faq {
            .c-tab-item-title{
                font-family: var(--font-light);
                font-size: 16px;
                line-height: 140%;
                letter-spacing: 0.16px;
                position: relative;
            }
            .c-tab-item{
                &.active{
                    .c-tab-item-title{
                        &:before{
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            height: 2px;
                            width: 100%;
                            background-color: var(--color-blue-1);
                        }
                    }
                }
            }
        }
    }
    .item{
        &-wrap{
            position: relative;
            overflow: hidden;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            @include s-1200{
                flex-wrap: wrap;
            }
        }
        &-left{
            flex: 1;
            padding-right: 80px;
            @include s-1200{
                flex:  0 0 100%;
                max-width: 100%;
                padding-right: 0;
            }
        }
        &-right{
            flex: 0 0 516px;
            max-width: 516px;
            @include s-1200{
                flex:  0 0 100%;
                max-width: 100%;
                padding-top: 24px;
            }
        }
        &-desc{
            padding-top: 24px;
        }
        &-list{
            margin: 0 -8px;
            display: flex;
            flex-wrap: wrap;
            @include s-768{
                margin: 0;
                padding-top: 32px;
            }
            &-col{
                padding-left: 8px;
                padding-right: 8px;
                padding-bottom: 16px;
                flex: 0 0 50%;
                max-width: 50%;
                @include s-1200{
                    flex: 0 0 25%;
                    max-width: 25%;
                }
                @include s-768{
                    flex:  0 0 100%;
                    max-width: 100%;
                    padding: 0 0 16px;
                }
            }
            &-block{
                padding: 24px;
                border-radius: 14px;
                border: 2px solid var(--color-grey-1);
                height: 100%;
                background-color: var(--color-grey-2);
                .el-text-help{
                    color: var(--color-blue);

                }
                .el-text-light{

                }
            }
        }

        &-content{
            transform: translateY(-100%);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            background-color: var(--color-red);
            color: var(--color-pink);
            z-index: 1;
            font-size: 20px;
            transition: all 0.3s ease-out;
            padding: 28px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
            @include s-1024{
                position: relative;
                transform: translateY(0);
                min-height: 200px;
            }
        }
        &-name{

        }

    }
}
