.site-width {
	width: 100%;
	margin: 0 auto;
	position: relative;
	padding-left: 32px;
	padding-right: 32px;
	max-width: 1264px;

	@include s-1200 {
		padding-left: 32px;
		padding-right: 32px;
		max-width: 834px;
	}
	@include s-768 {
		padding-left: 16px;
		padding-right: 16px;
	}
}

.container-fluid {
	width: 100%;
	padding-right: 10px;
	padding-left: 10px;
	margin-right: auto;
	margin-left: auto;
}

.container-padding {
	padding: 0 pxVw(156, 2560);

	@include s-1920 {
		padding: 0 pxVw(136, 1920);
	}

	@include s-1440 {
		padding: 0 pxVw(90, 1440);
	}

	@include s-1024 {
		padding: 0 pxVw(44, 1024);
	}

	@include s-768 {
		padding: 0 pxVw(44, 768);
	}

	@include s-640 {
		padding: 0 20px;
	}
}

.row {
	display: flex;
	flex-wrap: nowrap;
	margin-right: -16px;
	margin-left: -16px;
	@include s-768 {
		margin: 0;
	}
}

.col {
	padding-right: 16px;
	padding-left: 16px;
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	@include s-768{
		padding-left: 0;
		padding-right: 0;
	}
}
