.p-about {
	&-content{
		background-color: var(--color-grey-2);
	}
	.c-index {
		height: auto;
		min-height: 783px;
		position: relative;
		&-content {
			width: 100%;
			position: relative;
			z-index: 3;
			&-wrap{
				padding-top: 90px;
			}
		}
		&-mens{
			z-index: 2;
			position: relative;
			padding-top: 60px;
			.item-img-picture{
				margin: 0 auto;
				max-width: 716px;
			}
			.item-img--img{
				width: 100%;
			}
		}
		&-bg {
			height: 100%;
			width: 100%;

			position: absolute;
			left: 0;
			top: 0;
			z-index: 0;
			.item {
				&-img {
					&-picture{
						display: block;
						width: 100%;
						height: 100%;
						position: relative;
					}
					&--img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}
	.s-article{
		padding-top: 80px;
		padding-bottom: 80px;
		@include s-1200{
			padding-top: 48px;
			padding-bottom: 48px;
		}
		&-content{

		}
		&-row{
			padding-top: 100px;
			justify-content: space-between;
			&:first-of-type{
				padding-top: 0;
			}
			@include s-1200{
				flex-wrap: wrap;
			}
		}
		&-row{
			padding-top: 100px;
			justify-content: space-between;
			&:first-of-type{
				padding-top: 0;
			}
		}
		&-col{
			max-width:582px;
			flex: 0 0 50%;
				@include s-1200{
					max-width: 100%;
					flex: 0 0 100%;
				}
			&-desc{
				@include s-1200{
					padding-top: 32px;
				}
			}
		}
		.item{
			max-width: 550px;
			width: 100%;
			@include s-1200{
				max-width: 100%;
			}
			&-img{
				img{
					width: 100%;
				}
			}
			&-list{
				display: flex;
				flex-wrap: wrap;
				padding-top: 8px;
				justify-content: space-between;
				margin: 0 -8px;
				@include s-768{
					margin: 0;
				}
			}
			&-li{
				max-width: 250px;
				flex: 0 0 50%;
				padding-top: 32px;
				padding-left: 8px;
				padding-right: 8px;
				@include s-1200{
					max-width: 50%;

				}
				@include s-768{
					max-width: 100%;
					flex: 0 0 100%;
					padding-left: 0;
					padding-right: 0;
				}
				&-title{
					color: var(--color-blue);
					font-family: var(--font-med);
					line-height: 150%;
					font-size: 20px;
				}
				.el-text-light{
					padding-top: 4px;
				}
			}

		}
	}
	.s-clients{
		display: none;
		padding-top: 80px;
		@include s-1200{
			padding-top: 48px;
		}
		@include s-768{
			display: none;
		}
		&-wrap{

		}
		&-top{
			text-align: center;

		}

		.item{
			&-img{
				&-picture{
					img{
						width: 100%;
						object-fit: cover;
					}
				}

			}
		}
		&-img{
			padding-top: 32px;
			&-wrap{
				position: relative;
				overflow: hidden;
			}
		}
		&-locations{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			&-list{
				width: 100%;
				height: 100%;
				position: relative;
				.item{
					position: absolute;
					left: 0;
					top: 0;
					cursor: pointer;
					width: 10px;
					height: 10px;
					&:hover{
						& .item-tooltip{
							visibility: visible;
							opacity: 1 ;
							transform: translate(0,0);
						}
					}
					// &-01{
					// 	left: 8.83%;
					// 	top: 22.2%;

					// }
					// &-02{
					// 	left: 16.6%;
					// 	top: 22%;
					// }
					// &-03{
					// 	left: 20%;
					// 	top: 24.3%;
					// }
					// &-04{
					// 	left: 25%;
					// 	top: 25.9%;
					// }
					// &-05{
					// 	left: 49.3%;
					// 	top: 23.5%;
					// }
					// &-06{
					// 	left: 53.9%;
					// 	top: 21.2%;
					// }
					// &-07{
					// 	left: 22.5%;
					// 	top: 68%;
					// }
					&-wrap{
						position: relative;
					}
					&-anchor{
						width: 7px;
						height: 7px;
						background-color: var(--color-blue-1);
						border-radius: 50%;
						z-index: 1;
						position: relative;

					}

					&-tooltip{
						position: absolute;
						left: 0;
						top: 0;
						width: 200px;
						min-height: 104px;
						visibility: hidden;
						opacity: 1;
						transition: opacity 0.3s linear;
						transform: translate(-9999px,-9999px);
						z-index: 999;
						&-wrap{
							padding: 16px;
							border-radius: 22px;
							background-color: var(--color-white);
							border: 2px solid var(--color-blue-1);
						}
						&-logo{
							height: 24px;
							width: 100%;
							img{
								object-fit: contain;
							}
						}
						&-location{
							padding-top: 4px;
							font-size: 16px;
							font-family: var(--font-med);
							line-height: 140%;
							letter-spacing: 0.16px;
						}
						&-company{
							font-size: 16px;
							font-family: var(--font-light);
							line-height: 140%;
							letter-spacing: 0.16px;
						}
					}
				}
			}
		}

	}
}
