.p-pricing {
	&-content{
		background-color: var(--color-grey-2);
	}
	.c-index {
		height: auto;
		min-height: 413px;
		&-wrap{
			height: auto;
			min-height: 413px;
		}
		&-content{
			&-wrap{
				padding-top: 80px;
				padding-bottom: 80px;
				@include s-1200{
					padding-top: 48px;
					padding-bottom: 48px;
				}
			}
		}
	}
	.s-implementation{
		padding-top: 80px;
		@include s-1200{
			padding-top: 64px;
		}
		@include s-768{
			padding-top: 48px;
		}
		&-wrap{
			padding-top: 80px;
			@include s-768{
				padding-top: 0;
			}
		}
		&-top{
			@include s-768{
				text-align: center;
			}
			.item{
				&-label{
					text-transform: uppercase;
				}
				&-btn{
					padding-top: 24px;
				}
			}
		}

		&-row{
			padding-top: 100px;
			justify-content: space-between;
			flex-wrap: wrap;
			&:first-of-type{
				padding-top: 0;
			}
		}
		&-col{
			max-width: 50%;
			flex: 0 0 50%;
			@include s-768{
				max-width: 100%;
				flex: 0 0 100%;
				padding-left: 0;
				padding-right: 0;
			}
			&-right{
				@include s-768{
				padding-top: 32px;
					order: 1;
				}
			}
		}
		&-video{
			padding-top: 32px;
			max-width: 100%;
			flex: 0 0 100%;


			// &-wrap{
			// 	width: 100%;
			// 	height: auto;
			// 	position: relative;
			// 	padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
			// 	// #vimeo-container {
			// 	// 	position: relative;
			// 	// 	padding-bottom: 56.25%;
			// 	// 	height: 0;
			// 	// 	overflow: hidden;
			// 	// 	max-width: 100%;
			// 	// 	background-color: var(--color-grey-5);
			// 	// }
			// 	#vimeo-container iframe {
			// 		position: absolute;
			// 		top: 0;
			// 		left: 0;
			// 		width: 100%;
			// 		height: 100%;
			// 		border: 0;
			// 	}
			// 	.video-preview {
			// 		position: absolute;
			// 		top: 0;
			// 		left: 0;
			// 		width: 100%;
			// 		height: 100%;
			// 		background-color: #000;
			// 		display: flex;
			// 		justify-content: center;
			// 		align-items: center;
			// 		flex-direction: column;
			// 		z-index: 10;
			// 		transition: opacity 0.2s linear;
			// 		&.hidden{
			// 			visibility: hidden;
			// 			opacity: 0;
			// 		}
			// 	  }

			// 	  .preview-image {
			// 		width: 100%;
			// 		height: auto;
			// 		object-fit: cover;
			// 	  }

			// 	  .play-button {
			// 		position: absolute;
			// 		width: 154px;
			// 		height: 154px;
			// 		background: url('../icons/play.svg') no-repeat center center  ;
			// 		background-size: contain;
			// 		border: none;
			// 		cursor: pointer;
			// 	  }

			// 	  #vimeo-player {
			// 		position: absolute;
			// 		top: 0;
			// 		left: 0;
			// 		width: 100%;
			// 		height: 100%;
			// 	  }
			// }
		}
		// .item{
		// 	&-img{
		// 		&-picture{
		// 			img{
		// 				width: 100%;
		// 				object-fit: cover;
		// 			}
		// 		}

		// 	}
		// }

	}
	.s-tail{
		padding-top: 80px;
		&-wrap{

		}
		&-row{
			justify-content: space-between;
			@include s-1200{
				flex-wrap: wrap;
			}
		}
		&-left{
			flex: 0 0 598px;
			max-width: 598px;
			padding-top: 40px;
			@include s-1200{
				order: 1;
				flex: 0 0 100%;
				max-width: 100%;
				padding-top: 56px;
			}
			@include s-768{
				padding-top: 48px;
				text-align: center;
			}
			.item{
				&-list{
					padding-top: 24px;
					.el-text-help{
						color: var(--color-blue);
					}
					&-li{
						padding-top: 24px;
						&:first-of-type{
							padding-top: 0;
						}
					}
				}
			}
		}

		&-form{
			flex: 0 0 608px;
			max-width: 608px;
			@include s-1200{
				flex: 0 0 100%;
				max-width: 100%;
			}
			&-wrap{
				background-color: var(--color-white);
				padding: 40px;
				border-radius: 8px;
				box-shadow: 6px 13px 16px 0px rgba(108, 108, 108, 0.25);
				min-height: 673px;
				@include s-768{
					padding: 24px;
					min-height: 710px;
				}
			}
			.step{
				display: none;
				&-1{
					.form{
						&-item{
							&-actions{
								padding-top: 8px;
								padding-bottom: 0;
								&-wrap{
									padding-top: 0;
								}
							}

						}
					}
				}
				&-2{
					.form{
						&-item{
							&-actions{
								flex: 0 0 50%;
								padding: 0 8px;
								@include s-768{
									flex: 0 0 100%;
									max-width: 100%;
								}
								&:last-of-type{
									@include s-768{
										padding-top: 16px;
									}
								}
								&-wrap{

								}
								&-row{
									display: flex;
									flex-wrap: nowrap;
									margin: 0 -8px;
									@include s-768{
										flex-wrap: wrap;
									}
								}

							}

						}
					}
					.legal{
						padding-top: 16px;
					}
				}
				&.active{
					display: block;
				}
				.item-title{
					padding-top: 24px;
					padding-bottom: 24px;
				}
			}
			.item{
				&-step{
					text-align: center;
					font-size: 16px;
					font-family: var(--font-med);
					line-height: 140%;
					letter-spacing: 0.16px;
				}
			}
			form textarea{
				min-height: 105px;
			}
			form textarea{
				min-height: 105px;
			}

			.form{
				&-item{
					&-actions{
						.legal{
							padding-top: 15px;
						}
					}
				}
			}
		}

	}
}
