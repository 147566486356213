

.p-page{
    &-wrap{
        padding-top: 50px;
        @include s-1024{
            padding-top: 25px;
        }
        @include s-640{
            padding-top: 20px;
        }
    }
    &-content{
        // padding-top: 150px;
        // padding-bottom: 130px;
        // @include s-1024{
        //     padding-top: 100px;
        // }
        // @include s-768{
        //     padding-bottom: 80px;
        // }
    }
    .c-index {
		height: auto;
		min-height: 280px;
		position: relative;
        background-color: var(--color-blue);
		@include s-1200 {
			min-height: 400px;
		}
		@include s-1200 {
			min-height: 460px;
		}
		&-content {
			width: 100%;
			position: relative;
			z-index: 2;
			&-wrap {
				width: 100%;
				margin: 0 auto;
				display: flex;
				justify-content: flex-start;
				padding-top: 80px;
				padding-bottom: 80px;
				@include s-1200 {
					justify-content: center;
					padding-top: 64px;
					padding-bottom: 64px;
				}
				@include s-768 {
					padding-top: 48px;
					padding-bottom: 48px;
				}
			}
			&-col{
				width: 50%;
				@include s-1200 {
					width:100%;
				}
			}
			&-block{
				text-align: left;
				max-width: 742px;
				width: 100%;
				@include s-1200 {
					max-width: 100%;

				}

			}
			.item {
                &-label{
                    color: var(--color-white);
                }
				&-title {
					width: 100%;
					&--title {
						color: var(--color-white);
					}
				}
				&-desc {
					padding-top: 16px;
                    color: var(--color-white);

				}

			}
		}
	}
    .s-article{
        &-content{
            padding-top: 48px;
            padding-bottom: 80px;
        }
    }

}
.p-notfound{

    .site{
		height: 100vh;
		min-height: 670px;
	}
    &-content{
        padding-top: 80px;
        padding-bottom: 140px;
        min-height: 100vh;
        background-color: var(--color-grey-2);
        @include s-1200{
            padding-top: 64px;
            padding-bottom: 270px;
        }
        @include s-768{
            padding-top: 64px;
            padding-bottom: 270px;
        }
    }
    .c-index{
        &-content{
            &-wrap{
                display: block;
                padding-top: 0;
            }
            &-img{
                display: flex;
                justify-content: center;
                .item{
                    &-img{
                        &-picture{
                            max-width: 382px;
                            width: 100%;
                            img{

                            }
                        }
                    }
                }
            }
            &-col{
                padding-top: 48px;
            }
            &-block{
                .item{
                    &-btns{
                        padding-top: 32px;
                    }
                }
            }
        }
    }
}
.p-ready{
    &-content{
		background-color: var(--color-grey-1);
	}
    .c-index {
		height: auto;
		min-height: 538px;
		position: relative;

		&-content {
			width: 100%;
			position: relative;
			z-index: 2;
            min-height: calc(100vh - 600px);
            display: flex;
            align-items: flex-end;
			&-wrap {
				width: 100%;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
				padding-top: 36px;
				@include s-1200 {
					justify-content: center;
                    flex-wrap: wrap;

				}
				@include s-768 {
					padding-top: 40px;
					text-align: center;
				}
			}
			&-col{
				flex: 1;
				@include s-1200 {
					flex:  0 0 100%;
                    max-width: 100%;
				}
			}
            &-img{
                flex: 0 0 40%;
                max-width: 500px;
                @include s-1200 {
                    padding-top: 26px;
					flex:  0 0 100%;
                    max-width: 100%;
                    display: flex;
                    justify-content: center;
				}
            }
			&-block{
				text-align: left;
				max-width: 550px;
				width: 100%;
				margin: 0;
				@include s-1200 {
					max-width: 100%;
					text-align: center;
				}
				@include s-640 {
					// max-width: 220px;
				}

			}
			.item {
				&-title {
					width: 100%;
					&--title {
						// color: var(--color-pink);
					}
				}
				&-desc {
					padding-top: 16px;
				}
                &-img{

                }
                &-form{
                    padding-top: 32px;
                    .form-item{
                        &-email{
                            position: relative;
                            &-wrap{
                                position: relative;
                            }
                            input{
                                padding-left: 50px;
                                padding-right: 221px;
                                @include s-768{
                                    padding-right: 16px;
                                }
                            }
                        }
                        &-actions{
                            position: absolute;
                            right: 0;
                            top: 0;
                            max-width: 221px;
                            padding: 0;
                            @include s-768{
                               position: relative;
                               max-width: 100%;
                            }
                        }
                    }

                    .btn{
                        max-width: 221px;
                        @include s-768{
                            max-width: 100%;
                        }

                    }
                }

			}
		}

	}
}
