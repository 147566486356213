.p-aiml {
	&-content{
		background-color: var(--color-grey-2);
	}

    .c-successes{
        position: relative;
        z-index: 2;
        padding-top: 100px;
        padding-bottom: 60px;
		@include s-1200{
			padding-bottom: 48px;
		}
    }
	.s-solutions{
		padding-top: 80px;
		padding-bottom: 80px;
		@include s-1200{
			padding-top: 48px;
			padding-bottom: 48px;
		}
		&-wrap{

		}
		&-list{

		}
		.title-block{
			.item-title{
				text-align: center;
			}
		}
		&-content{
			padding-top: 56px;
		}
		&-row{
			padding-top: 100px;
			justify-content: space-between;
			&:first-of-type{
				padding-top: 0;
			}
		}
		&-col{
			max-width: 582px;
			flex: 0 0 50%;
			&-img{
				@include s-1200{
					display: none;
				}
			}
			&-desc{
				@include s-1200{
					max-width: 100%;
					flex: 0 0 100%;
				}
			}
		}
		.item{
			&-img{
				img{
					width: 100%;
				}
			}
			&-faq{
				padding-top: 24px;
			}
		}
		&-li{
			.item{
				&-label{
					&--title{

					}
				}
				&-desc{

				}
			}
		}
	}
}
